import { Select, Space, Typography } from 'antd';
import { PreferenceState } from 'digicust_types';
import type { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { usePreferenceCodes } from '../../utils/useCodes';
import useScrollHandler from './useScrollHandler';
import { useTranslation } from 'react-i18next';

const PreferenceSelect = ({
  value,
  onChange = () => null,
  onCopy = () => null,
  isCopied,
}: {
  value?: PreferenceState | null;
  onChange?: (value: PreferenceState | null) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();
  const preferenceCodes = usePreferenceCodes();
  const [PreferenceCode, setPreferenceCode] = useState(preferenceCodes);
  const selectRef = useRef<BaseSelectRef>(null);

  const handleSearch = (e: string) => {
    if (e?.length == 1) {
      const filterPrefrenceCode = preferenceCodes?.filter((preferenceValue) => {
        return preferenceValue?.code[0] == e;
      });
      setPreferenceCode(filterPrefrenceCode);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      onCopy();
      selectRef.current?.blur();
    }
  };

  const handleScroll = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useScrollHandler(handleScroll);

  useEffect(() => {
    setPreferenceCode(preferenceCodes);
  }, [preferenceCodes]);

  return (
    <Select
      variant="filled"
      ref={selectRef}
      showSearch
      style={{
        width: '100%',
        outline: isCopied ? '2px solid blue' : '',
        borderRadius: 6,
      }}
      popupMatchSelectWidth={600}
      placeholder={t('Preference')}
      value={value?.DE_A1200 === '' ? null : value?.DE_A1200}
      onSearch={handleSearch}
      onClick={handleClick}
      onChange={(e) =>
        onChange({
          ...(preferenceCodes
            .filter((item) => item.code === e)
            ?.map((item) => ({
              input: item.code,
              DE_A1200: item.code,
              value: Number(item.code) >= 200,
            }))?.[0] || {}),
          input: e,
        })
      }
    >
      {PreferenceCode?.filter((item) => item?.code)?.map((item, key) => (
        <Select.Option
          title={item.name}
          key={key}
          value={item.code}
          label={item.name}
        >
          <Space>
            <Typography.Text strong>{item.code}</Typography.Text>
            <Typography.Text
              style={{ maxWidth: '530px', display: 'flex' }}
              ellipsis
              type="secondary"
            >
              {item.name}
            </Typography.Text>
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PreferenceSelect;
