import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { ClassificationTypes, ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import AmountOfMoneyInput from '../../../../Components/Inputs/AmountOfMoneyInput';
import JSONataInput from '../../../../Components/Inputs/JSONataInput';
import PackageTypeSelect from '../../../../Components/Inputs/PackageTypeSelect';
import { DCTooltip } from '../../../../common';
import CardWrapper, { cardBorderCss } from '../../Common/CardWrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Components/reduxToolkit/store';
import CountrySelect from '../../../../Components/Inputs/CountrySelect';
import React from 'react';

export const DataNormalizationCard = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();
  const storeData = useSelector((state: RootState) => state?.statisticsSlice);
  const createCompany = storeData?.CreateCompany;

  return (
    <CardWrapper
      alternative
      title={t('Data Normalization')}
      description={t('Specify how Digicust makes sense out of information.')}
      checked={value?.dataNormalization?.active}
      onChange={(e) =>
        onValueChange({
          ...value,
          dataNormalization: {
            ...(value.dataNormalization || {}),
            active: e,
          },
        })
      }
    >
      {value.dataNormalization?.active && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Tariff Number')}
          </Typography.Paragraph>

          <div>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.tariffClassification?.active
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      tariffClassification: {
                        ...(value?.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.tariffClassification || {}),
                        active: e.target.checked,
                      },
                    },
                  },
                })
              }
            >
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginTop: -20, marginBottom: -8 }}
              />
              {t('Tariff classification')}
              <DCTooltip
                text={t(
                  'Automatically classifies goods based on goods descriptions up to the 11th digit.',
                )}
              />
            </Checkbox>
            {value.dataNormalization?.customsTariffNumberNormalizationSettings
              ?.tariffClassification?.active && (
              <Card style={cardBorderCss}>
                <Form layout="vertical">
                  <Form.Item label={t('Define the classification process')}>
                    <Select
                      popupMatchSelectWidth={false}
                      value={
                        !value?.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.tariffClassification?.active
                          ? ClassificationTypes.classifyAndSuggest
                          : value?.dataNormalization
                              ?.customsTariffNumberNormalizationSettings
                              ?.tariffClassification?.mode ||
                            ClassificationTypes.classifyAndSuggest
                      }
                      onChange={(classificationType) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            customsTariffNumberNormalizationSettings: {
                              ...(value.dataNormalization
                                ?.customsTariffNumberNormalizationSettings ||
                                {}),
                              tariffClassification: {
                                ...(value?.dataNormalization
                                  ?.customsTariffNumberNormalizationSettings
                                  ?.tariffClassification || {}),
                                mode: classificationType,
                                active:
                                  classificationType !==
                                  ClassificationTypes.noClassification,
                              },
                            },
                          },
                        })
                      }
                    >
                      {/* <Select.Option
                        value={ClassificationTypes.noClassification}
                      >
                        Disabled
                      </Select.Option> */}
                      <Select.Option
                        value={ClassificationTypes.classifyOnlyMissing}
                      >
                        {t('Classify only where missing')}
                      </Select.Option>
                      <Select.Option
                        value={ClassificationTypes.classifyAndSuggest}
                      >
                        {t('Classify and suggest')}
                      </Select.Option>
                      <Select.Option
                        value={ClassificationTypes.classifyAndReplace}
                      >
                        {t('Classify and replace')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Card>
            )}

            <div className="hoverable" style={{ display: 'flex' }}>
              {t('Map foreign tariff number to country')}
              <DCTooltip text={t(`Map to foreign tariff number for country`)} />
              <div style={{ width: '270px' }}>
                <CountrySelect
                  size="small"
                  value={{
                    alpha2Code:
                      value.dataNormalization
                        .customsTariffNumberNormalizationSettings
                        ?.mapForeignTariffNumberAlpha2Code,
                  }}
                  onChange={(e) =>
                    onValueChange({
                      ...value,
                      dataNormalization: {
                        ...(value.dataNormalization || {}),
                        customsTariffNumberNormalizationSettings: {
                          ...(value.dataNormalization
                            ?.customsTariffNumberNormalizationSettings || {}),
                          mapForeignTariffNumberAlpha2Code: e?.alpha2Code,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>

            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.autoFixTariffNumbers
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      autoFixTariffNumbers: e.target.checked,
                    },
                  },
                })
              }
            >
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginTop: -20, marginBottom: -8 }}
              />
              {t('Auto-fix tariff numbers')}
              <DCTooltip
                text={t(
                  'Transforms foreign tariff numbers (e.g. Chinese) into tariff numbers in the target tariff tree (e.g. German EZT).',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                (value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.customsTariffNumberLength || 0) > 0
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      customsTariffNumberLength: e.target.checked
                        ? 6
                        : undefined,
                    },
                  },
                })
              }
            >
              {t('Strip tariff number')}
              <DCTooltip
                text={t(
                  'Strips tariff numbers that we find on e.g. invoices to a shorter version for compatibility',
                )}
              />
            </Checkbox>
            {(value.dataNormalization?.customsTariffNumberNormalizationSettings
              ?.customsTariffNumberLength || 0) > 0 && (
              <Card style={cardBorderCss}>
                <Form layout="vertical">
                  <Form.Item label={t('Strip tariff number to')}>
                    <Select
                      popupMatchSelectWidth={false}
                      style={{ width: '100%' }}
                      value={
                        value.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.customsTariffNumberLength ?? 100
                      }
                      options={[
                        // {
                        //   label: 'Do not strip tariff number',
                        //   value: 100,
                        // },
                        {
                          label: t('First 6 digits'),
                          value: 6,
                        },
                        {
                          label: t('First 8 digits'),
                          value: 8,
                        },
                        {
                          label: t('First 10 digits'),
                          value: 10,
                        },
                        {
                          label: t('First 11 digits'),
                          value: 11,
                        },
                      ]}
                      onChange={(e) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            customsTariffNumberNormalizationSettings: {
                              ...(value.dataNormalization
                                ?.customsTariffNumberNormalizationSettings ||
                                {}),
                              customsTariffNumberLength:
                                e === 100 ? undefined : e,
                            },
                          },
                        })
                      }
                    />
                  </Form.Item>
                </Form>
              </Card>
            )}
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.useAsItemDescription
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      useAsItemDescription: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Use tariff number as item description')}
              <DCTooltip
                text={t(
                  'Uses the description of the tariff number automatically as item description.',
                )}
              />
            </Checkbox>
            {value.dataNormalization?.customsTariffNumberNormalizationSettings
              ?.useAsItemDescription && (
              <Card style={cardBorderCss}>
                <Form layout="vertical">
                  <Form.Item label={t('Source')}>
                    <Select
                      popupMatchSelectWidth={false}
                      placeholder={t('Use Tariff Description')}
                      value={
                        !value?.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.useAsItemDescription
                          ? false
                          : value?.dataNormalization
                              ?.customsTariffNumberNormalizationSettings
                              ?.itemDescriptionSource || 'description'
                      }
                      onChange={(e) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            customsTariffNumberNormalizationSettings: {
                              ...(value.dataNormalization
                                ?.customsTariffNumberNormalizationSettings ||
                                {}),
                              itemDescriptionSource:
                                typeof e === 'boolean' ? undefined : e,
                              useAsItemDescription: !(e === false),
                            },
                          },
                        })
                      }
                    >
                      {/* <Select.Option value={false}>Disabled</Select.Option> */}
                      <Select.Option value={'description'}>
                        {t('Official Description')}
                      </Select.Option>
                      <Select.Option value={'altDescription'}>
                        {t('Improved Description')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Space style={{ marginBottom: 20 }}>
                    <Checkbox
                      className="hoverable"
                      checked={
                        value.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.hideOriginalItemDescription
                      }
                      onChange={(e) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            customsTariffNumberNormalizationSettings: {
                              ...(value.dataNormalization
                                ?.customsTariffNumberNormalizationSettings ||
                                {}),
                              hideOriginalItemDescription: e.target.checked,
                            },
                          },
                        })
                      }
                    >
                      {t('Hide Original Item Description')}
                    </Checkbox>

                    <Checkbox
                      className="hoverable"
                      checked={
                        value.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.preferMasterDataDescription
                      }
                      onChange={(e) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            customsTariffNumberNormalizationSettings: {
                              ...(value.dataNormalization
                                ?.customsTariffNumberNormalizationSettings ||
                                {}),
                              preferMasterDataDescription: e.target.checked,
                            },
                          },
                        })
                      }
                    >
                      {t('Prefer Description from Master Data')}
                    </Checkbox>
                  </Space>
                  <div
                    className="hoverable"
                    style={{ display: 'inline-block' }}
                  >
                    {t('Max length')}
                    <DCTooltip
                      text={t(
                        'Specifies the maximum length of the combined item description (e.g. prefix + tariff number description + suffix).',
                      )}
                    />
                    <InputNumber
                      size="small"
                      style={{ width: '120px' }}
                      suffix={t('characters')}
                      min={0}
                      max={1000}
                      step={1}
                      value={Math.round(
                        value.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.combinedMaxDescriptionLength || 238,
                      )}
                      onChange={(val) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            customsTariffNumberNormalizationSettings: {
                              ...(value?.dataNormalization
                                ?.customsTariffNumberNormalizationSettings ||
                                {}),
                              combinedMaxDescriptionLength: val || undefined,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </Form>
              </Card>
            )}

            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.disableLineItemDescriptionShortening
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      disableLineItemDescriptionShortening: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Disable line item description shortening')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.considerCustomsTariffNumberFromInvoiceHead
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      considerCustomsTariffNumberFromInvoiceHead:
                        e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Consider tariff number from invoice head')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.ignoreCustomsTariffNumbersFromInvoice
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      ignoreCustomsTariffNumbersFromInvoice: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Ignore customs tariff number from invoice')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.customsTariffNumberNormalizationSettings?.complianceCheck
                  ?.active
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberNormalizationSettings: {
                      ...(value.dataNormalization
                        ?.customsTariffNumberNormalizationSettings || {}),
                      complianceCheck: {
                        ...(value.dataNormalization
                          ?.customsTariffNumberNormalizationSettings
                          ?.complianceCheck || {}),
                        active: e.target.checked,
                      },
                    },
                  },
                })
              }
            >
              {t('Compliance Check')}
              <DCTooltip text={t('Checks the validity of tariff numbers')} />
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                (value?.dataNormalization?.customsTariffNumberMappingJsonAta
                  ?.length || 0) > 0
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    customsTariffNumberMappingJsonAta: e.target.checked
                      ? 'tariffNumber'
                      : '',
                  },
                })
              }
            >
              {t('Tariff number mapping')}
              <DCTooltip
                text={t(
                  'Custom formatting for transforming the (auto fixed) customs tariff number into a new tariff number.',
                )}
              />
            </Checkbox>
            {(value.dataNormalization?.customsTariffNumberMappingJsonAta
              ?.length || 0) > 0 && (
              <Card style={cardBorderCss}>
                <>
                  <Form layout="vertical">
                    <Form.Item
                      label={t('JSONata Format String')}
                      tooltip={t(
                        'The format string for the resulting description of line items.',
                      )}
                    >
                      <JSONataInput
                        inputValue={
                          value?.dataNormalization
                            ?.customsTariffNumberMappingJsonAta
                        }
                        sampleInput={{
                          tariffNumber: '1234567890',
                          quantity: 1234,
                          tariffNumberDescription:
                            'Einrichtungen, Maschinen, Apparate und Geräte zur Kälteerzeugung',
                          lineItemDescription:
                            'Orsiro Mission 2.5/9 PTCA-Stents ',
                          lineItemDescriptionShort:
                            'Orsiro Mission PTCA-Stents ',
                          materialDescription: 'PCTA-Stents',
                          enrichedDescription: 'PCTA-Stents',
                          enrichedDescriptionShort: 'Stents',
                          preferMaterialMasterData: false,
                          materialMatched: true,
                          useCustomsTariffNumberDescription: true,
                          useLineItemDescriptionShortening: true,
                          materialMatchedByTariffNumber: true,
                          materialMatchedByMaterialNr: false,
                        }}
                        onChange={(e) =>
                          onValueChange({
                            ...value,
                            dataNormalization: {
                              ...(value.dataNormalization || {}),
                              customsTariffNumberMappingJsonAta: e,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </Form>
                  <div>
                    <h3>{t('JSONata Formatting Options')}</h3>
                    <p>
                      {t(
                        'Custom formatting for transforming the (autofixed) customs tariff number into a new tariff number.',
                      )}
                    </p>
                    <p>
                      <b>
                        {t(
                          'The input JSON object is expected to have the following structure:',
                        )}
                      </b>
                    </p>
                    <p>
                      {`{
                          tariffNumber: '1234567890',
                          quantity: 1234,
                          tariffNumberDescription:
                            'Einrichtungen, Maschinen, Apparate und Geräte zur Kälteerzeugung',
                          lineItemDescription:
                            'Orsiro Mission 2.5/9 PTCA-Stents ',
                          lineItemDescriptionShort:
                            'Orsiro Mission PTCA-Stents ',
                          materialDescription: 'PCTA-Stents',
                          enrichedDescription: 'PCTA-Stents',
                          enrichedDescriptionShort: 'Stents',
                          preferMaterialMasterData: false,
                          materialMatched: true,
                          useCustomsTariffNumberDescription: true,
                          useLineItemDescriptionShortening: true,
                          materialMatchedByTariffNumber: true,
                          materialMatchedByMaterialNr: false
                        }`}
                    </p>
                  </div>
                </>
              </Card>
            )}
          </div>
          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Master Data')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={value.matchingEngine?.materialMatching?.disabled}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      disabled: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Disable matching')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.matchingEngine?.materialMatching
                  ?.disableTariffNumberMatching
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      disableTariffNumberMatching: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Disable tariff number matching')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.matchingEngine?.materialMatching
                  ?.disableMaterialNumberMatching
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      disableMaterialNumberMatching: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Disable material number matching')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.matchingEngine?.materialMatching
                  ?.sendMasterDataTariffNumberOnly
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      sendMasterDataTariffNumberOnly: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Send only master data tariff number')}
              <DCTooltip
                text={t(
                  'Send only user edited master data Tariff Number to the subsequent customs software.',
                )}
              />
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.matchingEngine?.materialMatching
                  ?.sendMasterDataDescriptionOnly
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      sendMasterDataDescriptionOnly: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Send only master data description')}
              <DCTooltip
                text={t(
                  'Send only user edited master data Description to the subsequent customs software.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                !!value.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration?.stakeholderFilters
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      tariffNumberMatchingConfiguration: {
                        ...(value.matchingEngine?.materialMatching
                          ?.tariffNumberMatchingConfiguration || {}),
                        stakeholderFilters: e.target.checked ? [] : undefined,
                      },
                    },
                  },
                })
              }
            >
              {t('Tariff number master data matching by stakeholders')}
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                !!value.matchingEngine?.materialMatching
                  ?.tariffNumberMatchingConfiguration?.matchShipperCountry
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  matchingEngine: {
                    ...(value.matchingEngine || {}),
                    materialMatching: {
                      ...(value.matchingEngine?.materialMatching || {}),
                      tariffNumberMatchingConfiguration: {
                        ...(value.matchingEngine?.materialMatching
                          ?.tariffNumberMatchingConfiguration || {}),
                        matchShipperCountry: e.target.checked,
                      },
                    },
                  },
                })
              }
            >
              {t('Match by shipper country')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                !!value.dataNormalization.disableAutomaticStakeholderCreation
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    disableAutomaticStakeholderCreation: e.target.checked,
                  },
                })
              }
            >
              {t('Disable automatic stakeholder creation')}
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                !!value.dataNormalization.disableAutomaticMaterialCreation
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    disableAutomaticMaterialCreation: e.target.checked,
                  },
                })
              }
            >
              {t('Disable automatic material creation')}
            </Checkbox>

            {!!value.matchingEngine?.materialMatching
              ?.tariffNumberMatchingConfiguration?.stakeholderFilters && (
              <Card style={cardBorderCss}>
                <Form layout="vertical">
                  <Form.Item label={t('Stakeholder Filters')}>
                    <Select
                      popupMatchSelectWidth={false}
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder={t('Please select')}
                      defaultValue={
                        value.matchingEngine?.materialMatching
                          ?.tariffNumberMatchingConfiguration
                          ?.stakeholderFilters
                      }
                      options={[
                        'shipper',
                        'consignee',
                        'recipient',
                        'beneficiary',
                        'applicant',
                        'declarant',
                        'importer',
                        'exporter',
                        'buyer',
                        'agent',
                        'broker',
                        'carrier',
                        'warehouse',
                        'obligater',
                      ].map((value) => ({
                        value,
                        label: value.charAt(0).toUpperCase() + value.slice(1),
                      }))}
                      onChange={(e) =>
                        onValueChange({
                          ...value,
                          matchingEngine: {
                            ...(value.matchingEngine || {}),
                            materialMatching: {
                              ...(value.matchingEngine?.materialMatching || {}),
                              tariffNumberMatchingConfiguration: {
                                ...(value.matchingEngine?.materialMatching
                                  ?.tariffNumberMatchingConfiguration || {}),
                                stakeholderFilters: e,
                              },
                            },
                          },
                        })
                      }
                    />
                  </Form.Item>
                </Form>
              </Card>
            )}
          </div>

          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Weight')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization
                  ?.aggregateGrossWeightFromInvoicesWithPackageInformationOnly
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    aggregateGrossWeightFromInvoicesWithPackageInformationOnly:
                      e.target.checked,
                  },
                })
              }
            >
              {t('Use Gross Weight from Package Information Only')}
              <DCTooltip
                text={t(
                  'This function takes the gross weight only from invoices that have package information, such as the type and number of packages. If the invoice doesn’t include these details, its weight will be ignored in the calculation of the total gross weight.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.lineItemGrossWeightStrategy ===
                'forceToFirstLineItem'
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    lineItemGrossWeightStrategy: e.target.checked
                      ? 'forceToFirstLineItem'
                      : 'default',
                  },
                })
              }
            >
              {t('Force gross weight to first line item')}
              <DCTooltip
                text={t(
                  'You can choose to force the line item gross weight to the first line item here.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.preferWeightFromHead || false}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    preferWeightFromHead: e.target.checked || undefined,
                  },
                })
              }
            >
              {t('Prefer weight information from head')}
              <DCTooltip
                text={t(
                  'Weight information can come from line items or from the document head. Activate, if you want to prefer weight information from the document head.',
                )}
              />
            </Checkbox>
            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Calculate line item weight')}
              <DCTooltip
                text={t(
                  'Often, no specific weight information is given for line items. Digicust automatically calculates the weight of line items based on the total weight of the document. You can choose how the weight is distributed here.',
                )}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                placeholder={t('Line Item Weight Strategy')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.calculateLineItemWeight ||
                  'byQuantity'
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      calculateLineItemWeight: e,
                    },
                  })
                }
              >
                <Select.Option value="byQuantity">
                  {t('By Quantity')}
                </Select.Option>
                <Select.Option value="byTotalValue">
                  {t('By Total Value')}
                </Select.Option>
              </Select>
            </div>
            <Checkbox
              className="hoverable"
              checked={!!value?.dataNormalization?.calculateWeight || false}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    calculateWeight: e.target.checked ? {} : undefined,
                  },
                })
              }
            >
              {t('Calculate weight')}
              <DCTooltip
                text={t(
                  'Define how net weight and gross weight are being calculated (if not explicitly given on documents).',
                )}
              />
            </Checkbox>
            {value.dataNormalization?.calculateWeight && (
              <>
                <div className="hoverable" style={{ display: 'inline-block' }}>
                  {t('Gross weight from net weight')}
                  <DCTooltip
                    text={t(
                      'Often, no specific gross weight is available on documents. In that case, Digicust calculates the gross weight from the net weight. You can choose how the gross weight is calculated here.',
                    )}
                  />
                  <InputNumber
                    size="small"
                    value={
                      value.dataNormalization?.calculateWeight
                        ?.grossWeightFromNetWeight || 1.1
                    }
                    type="number"
                    onChange={(e) =>
                      onValueChange({
                        ...value,
                        dataNormalization: {
                          ...(value.dataNormalization || {}),
                          calculateWeight: {
                            ...(value.dataNormalization?.calculateWeight || {}),
                            grossWeightFromNetWeight: e || 1.1,
                          },
                        },
                      })
                    }
                  />
                </div>
                <div className="hoverable" style={{ display: 'inline-block' }}>
                  {t('Net weight from gross weight')}
                  <DCTooltip
                    text={t(
                      'Often, no specific net weight is available on documents. In that case, Digicust calculates the net weight from the gross weight. You can choose how the net weight is calculated here.',
                    )}
                  />
                  <InputNumber
                    value={
                      value.dataNormalization?.calculateWeight
                        ?.netWeightFromGrossWeight || 0.9
                    }
                    size="small"
                    type="number"
                    onChange={(e) =>
                      onValueChange({
                        ...value,
                        dataNormalization: {
                          ...(value.dataNormalization || {}),
                          calculateWeight: {
                            ...(value.dataNormalization?.calculateWeight || {}),
                            netWeightFromGrossWeight: e || 0.9,
                          },
                        },
                      })
                    }
                  />
                </div>
              </>
            )}
          </div>

          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Goods Description')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.translateLineItemDescriptions?.active
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    translateLineItemDescriptions: {
                      ...(value?.dataNormalization
                        ?.translateLineItemDescriptions || {}),
                      active: e.target.checked,
                    },
                  },
                })
              }
            >
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginTop: -20, marginBottom: -8 }}
              />
              {t('Translate item descriptions')}
              <DCTooltip
                text={t(
                  'Digicust can translate item descriptions. If you activate this, Digicust will translate item descriptions automatically.',
                )}
              />
            </Checkbox>
            {value.dataNormalization?.translateLineItemDescriptions?.active && (
              <Card style={cardBorderCss}>
                <Form layout="vertical">
                  <Form.Item
                    label={t('Target language')}
                    tooltip={t(
                      'The language in which to translate the item descriptions.',
                    )}
                  >
                    <Select
                      popupMatchSelectWidth={false}
                      placeholder={t('Target language')}
                      optionFilterProp="children"
                      value={
                        value.dataNormalization?.translateLineItemDescriptions
                          ?.destinationLanguage
                      }
                      showSearch
                      onChange={(e) =>
                        onValueChange({
                          ...value,
                          dataNormalization: {
                            ...(value.dataNormalization || {}),
                            translateLineItemDescriptions: {
                              ...(value?.dataNormalization
                                ?.translateLineItemDescriptions || {}),
                              destinationLanguage: e,
                            },
                          },
                        })
                      }
                    >
                      {[
                        {
                          label: 'Afrikaans',
                          code: 'af',
                        },
                        {
                          label: 'Albanian',
                          code: 'sq',
                        },
                        {
                          label: 'Amharic',
                          code: 'am',
                        },
                        {
                          label: 'Arabic',
                          code: 'ar',
                        },
                        {
                          label: 'Armenian',
                          code: 'hy',
                        },
                        {
                          label: 'Assamese',
                          code: 'as',
                        },
                        {
                          label: 'Azerbaijani (Latin)',
                          code: 'az',
                        },
                        {
                          label: 'Bangla',
                          code: 'bn',
                        },
                        {
                          label: 'Bashkir',
                          code: 'ba',
                        },
                        {
                          label: 'Basque',
                          code: 'eu',
                        },
                        {
                          label: 'Bhojpuri',
                          code: 'bho',
                        },
                        {
                          label: 'Bodo',
                          code: 'brx',
                        },
                        {
                          label: 'Bosnian (Latin)',
                          code: 'bs',
                        },
                        {
                          label: 'Bulgarian',
                          code: 'bg',
                        },
                        {
                          label: 'Cantonese (Traditional)',
                          code: 'yue',
                        },
                        {
                          label: 'Catalan',
                          code: 'ca',
                        },
                        {
                          label: 'Chinese (Literary)',
                          code: 'lzh',
                        },
                        {
                          label: 'Chinese Simplified',
                          code: 'zh-Hans',
                        },
                        {
                          label: 'Chinese Traditional',
                          code: 'zh-Hant',
                        },
                        {
                          label: 'chiShona',
                          code: 'sn',
                        },
                        {
                          label: 'Croatian',
                          code: 'hr',
                        },
                        {
                          label: 'Czech',
                          code: 'cs',
                        },
                        {
                          label: 'Danish',
                          code: 'da',
                        },
                        {
                          label: 'Dari',
                          code: 'prs',
                        },
                        {
                          label: 'Divehi',
                          code: 'dv',
                        },
                        {
                          label: 'Dogri',
                          code: 'doi',
                        },
                        {
                          label: 'Dutch',
                          code: 'nl',
                        },
                        {
                          label: 'English',
                          code: 'en',
                        },
                        {
                          label: 'Estonian',
                          code: 'et',
                        },
                        {
                          label: 'Faroese',
                          code: 'fo',
                        },
                        {
                          label: 'Fijian',
                          code: 'fj',
                        },
                        {
                          label: 'Filipino',
                          code: 'fil',
                        },
                        {
                          label: 'Finnish',
                          code: 'fi',
                        },
                        {
                          label: 'French',
                          code: 'fr',
                        },
                        {
                          label: 'French (Canada)',
                          code: 'fr-ca',
                        },
                        {
                          label: 'Galician',
                          code: 'gl',
                        },
                        {
                          label: 'Georgian',
                          code: 'ka',
                        },
                        {
                          label: 'German',
                          code: 'de',
                        },
                        {
                          label: 'Greek',
                          code: 'el',
                        },
                        {
                          label: 'Gujarati',
                          code: 'gu',
                        },
                        {
                          label: 'Haitian Creole',
                          code: 'ht',
                        },
                        {
                          label: 'Hausa',
                          code: 'ha',
                        },
                        {
                          label: 'Hebrew',
                          code: 'he',
                        },
                        {
                          label: 'Hindi',
                          code: 'hi',
                        },
                        {
                          label: 'Hmong Daw (Latin)',
                          code: 'mww',
                        },
                        {
                          label: 'Hungarian',
                          code: 'hu',
                        },
                        {
                          label: 'Icelandic',
                          code: 'is',
                        },
                        {
                          label: 'Igbo',
                          code: 'ig',
                        },
                        {
                          label: 'Indonesian',
                          code: 'id',
                        },
                        {
                          label: 'Inuinnaqtun',
                          code: 'ikt',
                        },
                        {
                          label: 'Inuktitut',
                          code: 'iu',
                        },
                        {
                          label: 'Inuktitut (Latin)',
                          code: 'iu-Latn',
                        },
                        {
                          label: 'Irish',
                          code: 'ga',
                        },
                        {
                          label: 'Italian',
                          code: 'it',
                        },
                        {
                          label: 'Japanese',
                          code: 'ja',
                        },
                        {
                          label: 'Kannada',
                          code: 'kn',
                        },
                        {
                          label: 'Kashmiri',
                          code: 'ks',
                        },
                        {
                          label: 'Kazakh',
                          code: 'kk',
                        },
                        {
                          label: 'Khmer',
                          code: 'km',
                        },
                        {
                          label: 'Kinyarwanda',
                          code: 'rw',
                        },
                        {
                          label: 'Klingon',
                          code: 'tlh-Latn',
                        },
                        {
                          label: 'Klingon (plqaD)',
                          code: 'tlh-Piqd',
                        },
                        {
                          label: 'Konkani',
                          code: 'gom',
                        },
                        {
                          label: 'Korean',
                          code: 'ko',
                        },
                        {
                          label: 'Kurdish (Central)',
                          code: 'ku',
                        },
                        {
                          label: 'Kurdish (Northern)',
                          code: 'kmr',
                        },
                        {
                          label: 'Kyrgyz (Cyrillic)',
                          code: 'ky',
                        },
                        {
                          label: 'Lao',
                          code: 'lo',
                        },
                        {
                          label: 'Latvian',
                          code: 'lv',
                        },
                        {
                          label: 'Lithuanian',
                          code: 'lt',
                        },
                        {
                          label: 'Lingala',
                          code: 'ln',
                        },
                        {
                          label: 'Lower Sorbian',
                          code: 'dsb',
                        },
                        {
                          label: 'Luganda',
                          code: 'lug',
                        },
                        {
                          label: 'Macedonian',
                          code: 'mk',
                        },
                        {
                          label: 'Maithili',
                          code: 'mai',
                        },
                        {
                          label: 'Malagasy',
                          code: 'mg',
                        },
                        {
                          label: 'Malay (Latin)',
                          code: 'ms',
                        },
                        {
                          label: 'Malayalam',
                          code: 'ml',
                        },
                        {
                          label: 'Maltese',
                          code: 'mt',
                        },
                        {
                          label: 'Maori',
                          code: 'mi',
                        },
                        {
                          label: 'Marathi',
                          code: 'mr',
                        },
                        {
                          label: 'Mongolian (Cyrillic)',
                          code: 'mn-Cyrl',
                        },
                        {
                          label: 'Mongolian (Traditional)',
                          code: 'mn-Mong',
                        },
                        {
                          label: 'Myanmar',
                          code: 'my',
                        },
                        {
                          label: 'Nepali',
                          code: 'ne',
                        },
                        {
                          label: 'Norwegian',
                          code: 'nb',
                        },
                        {
                          label: 'Nyanja',
                          code: 'nya',
                        },
                        {
                          label: 'Odia',
                          code: 'or',
                        },
                        {
                          label: 'Pashto',
                          code: 'ps',
                        },
                        {
                          label: 'Persian',
                          code: 'fa',
                        },
                        {
                          label: 'Polish',
                          code: 'pl',
                        },
                        {
                          label: 'Portuguese (Brazil)',
                          code: 'pt',
                        },
                        {
                          label: 'Portuguese (Portugal)',
                          code: 'pt-pt',
                        },
                        {
                          label: 'Punjabi',
                          code: 'pa',
                        },
                        {
                          label: 'Queretaro Otomi',
                          code: 'otq',
                        },
                        {
                          label: 'Romanian',
                          code: 'ro',
                        },
                        {
                          label: 'Rundi',
                          code: 'run',
                        },
                        {
                          label: 'Russian',
                          code: 'ru',
                        },
                        {
                          label: 'Samoan (Latin)',
                          code: 'sm',
                        },
                        {
                          label: 'Serbian (Cyrillic)',
                          code: 'sr-Cyrl',
                        },
                        {
                          label: 'Serbian (Latin)',
                          code: 'sr-Latn',
                        },
                        {
                          label: 'Sesotho',
                          code: 'st',
                        },
                        {
                          label: 'Sesotho sa Leboa',
                          code: 'nso',
                        },
                        {
                          label: 'Setswana',
                          code: 'tn',
                        },
                        {
                          label: 'Sindhi',
                          code: 'sd',
                        },
                        {
                          label: 'Sinhala',
                          code: 'si',
                        },
                        {
                          label: 'Slovak',
                          code: 'sk',
                        },
                        {
                          label: 'Slovenian',
                          code: 'sl',
                        },
                        {
                          label: 'Somali (Arabic)',
                          code: 'so',
                        },
                        {
                          label: 'Spanish',
                          code: 'es',
                        },
                        {
                          label: 'Swahili (Latin)',
                          code: 'sw',
                        },
                        {
                          label: 'Swedish',
                          code: 'sv',
                        },
                        {
                          label: 'Tahitian',
                          code: 'ty',
                        },
                        {
                          label: 'Tamil',
                          code: 'ta',
                        },
                        {
                          label: 'Tatar (Latin)',
                          code: 'tt',
                        },
                        {
                          label: 'Telugu',
                          code: 'te',
                        },
                        {
                          label: 'Thai',
                          code: 'th',
                        },
                        {
                          label: 'Tibetan',
                          code: 'bo',
                        },
                        {
                          label: 'Tigrinya',
                          code: 'ti',
                        },
                        {
                          label: 'Tongan',
                          code: 'to',
                        },
                        {
                          label: 'Turkish',
                          code: 'tr',
                        },
                        {
                          label: 'Turkmen (Latin)',
                          code: 'tk',
                        },
                        {
                          label: 'Ukrainian',
                          code: 'uk',
                        },
                        {
                          label: 'Upper Sorbian',
                          code: 'hsb',
                        },
                        {
                          label: 'Urdu',
                          code: 'ur',
                        },
                        {
                          label: 'Uyghur (Arabic)',
                          code: 'ug',
                        },
                        {
                          label: 'Uzbek (Latin)',
                          code: 'uz',
                        },
                        {
                          label: 'Vietnamese',
                          code: 'vi',
                        },
                        {
                          label: 'Welsh',
                          code: 'cy',
                        },
                        {
                          label: 'Xhosa',
                          code: 'xh',
                        },
                        {
                          label: 'Yoruba',
                          code: 'yo',
                        },
                        {
                          label: 'Yucatec Maya',
                          code: 'yua',
                        },
                        {
                          label: 'Zulu',
                          code: 'zu',
                        },
                      ].map((lang) => (
                        <Select.Option value={lang.code}>
                          {lang.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Card>
            )}

            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.searchForItemDescriptionOnInternet
                  ?.active
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    searchForItemDescriptionOnInternet: {
                      ...(value?.dataNormalization
                        ?.searchForItemDescriptionOnInternet || {}),
                      active: e.target.checked,
                    },
                  },
                })
              }
            >
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginTop: -20, marginBottom: -8 }}
              />
              {t('Search for item description on the web')}
              <DCTooltip
                text={t(
                  'Will try to find additional product information on Google.',
                )}
              />
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                (value?.dataNormalization?.descriptionFormatString?.length ||
                  value?.dataNormalization?.descriptionFormatStringJsonAta
                    ?.length ||
                  0) > 0
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    descriptionFormatString: e.target.checked
                      ? '{line-item-description}'
                      : '',
                    descriptionFormatStringJsonAta: '',
                  },
                })
              }
            >
              {t('Custom goods description')}
              <DCTooltip
                text={t(
                  'Custom formattings for the resulting description of line items.',
                )}
              />
            </Checkbox>
            {(value.dataNormalization?.descriptionFormatString?.length ||
              value.dataNormalization?.descriptionFormatStringJsonAta?.length ||
              0) > 0 && (
              <Card style={cardBorderCss}>
                <Radio.Group
                  value={
                    value.dataNormalization?.descriptionFormatStringJsonAta
                      ?.length
                      ? 'b'
                      : 'a'
                  }
                  onChange={(e) => {
                    if (e.target.value === 'a') {
                      onValueChange({
                        ...value,
                        dataNormalization: {
                          ...(value?.dataNormalization || {}),
                          descriptionFormatString:
                            value.dataNormalization?.descriptionFormatString ||
                            '{line-item-description}',
                          descriptionFormatStringJsonAta: '',
                        },
                      });
                    } else {
                      onValueChange({
                        ...value,
                        dataNormalization: {
                          ...(value?.dataNormalization || {}),
                          descriptionFormatString: '',
                          descriptionFormatStringJsonAta:
                            value.dataNormalization
                              ?.descriptionFormatStringJsonAta ||
                            `useCustomsTariffNumberDescription
                              and (
                                preferMaterialMasterData = false
                              or materialMatched = false
                            )
                              and $exists(tariffNumberDescription)
                              and $string(tariffNumberDescription) != ""
                                ? tariffNumberDescription & " - " & (
                                useLineItemDescriptionShortening = true
                                and materialMatched = false
                                ? (($exists(lineItemDescriptionShort) and $string(lineItemDescriptionShort) != "") ? lineItemDescriptionShort: lineItemDescription)
                                : (
                                  materialMatched = true
                                    ? materialDescription
                                    : (
                                      useLineItemDescriptionShortening = true
                                        ? lineItemDescriptionShort
                                        : lineItemDescription
                                    )
                                )
                            )
                            : enrichedDescription`,
                        },
                      });
                    }
                  }}
                  size="small"
                >
                  <Radio.Button value="a">{t('Simple')}</Radio.Button>
                  <Radio.Button value="b">
                    {t('Advanced (JSONata)')}
                  </Radio.Button>
                </Radio.Group>
                {value.dataNormalization?.descriptionFormatStringJsonAta ? (
                  <>
                    <Form layout="vertical">
                      <Form.Item
                        label={t('JSONata Format String')}
                        tooltip={t(
                          'The format string for the resulting description of line items.',
                        )}
                      >
                        <JSONataInput
                          inputValue={
                            value?.dataNormalization
                              ?.descriptionFormatStringJsonAta
                          }
                          sampleInput={{
                            tariffNumberDescription:
                              'Einrichtungen, Maschinen, Apparate und Geräte zur Kälteerzeugung',
                            lineItemDescription:
                              'Orsiro Mission 2.5/9 PTCA-Stents ',
                            lineItemDescriptionShort:
                              'Orsiro Mission PTCA-Stents ',
                            materialDescription: 'PCTA-Stents',
                            enrichedDescription: 'PCTA-Stents',
                            enrichedDescriptionShort: 'Stents',
                            preferMaterialMasterData: false,
                            materialMatched: true,
                            useCustomsTariffNumberDescription: true,
                            useLineItemDescriptionShortening: true,
                            materialMatchedByTariffNumber: true,
                            materialMatchedByMaterialNr: false,
                          }}
                          onChange={(e) =>
                            onValueChange({
                              ...value,
                              dataNormalization: {
                                ...(value.dataNormalization || {}),
                                descriptionFormatStringJsonAta: e,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Form>
                    <div>
                      <h3>{t('JSONata Formatting Options')}</h3>
                      <p>
                        {t(
                          'Custom formattings for the resulting description of line items as a JSONAta query.',
                        )}
                      </p>
                      <p>
                        <b>
                          {t(
                            'The input JSON object is expected to have the following structure:',
                          )}
                        </b>
                      </p>
                      <p>
                        {`{
                            "tariffNumberDescription": "Einrichtungen, Maschinen, Apparate und Geräte zur Kälteerzeugung",
                            "lineItemDescription": "Orsiro Mission 2.5/9 PTCA-Stents ",
                            "lineItemDescriptionShort": "Orsiro Mission PTCA-Stents ",
                            "materialDescription": "PCTA-Stents",
                            "enrichedDescription": "PCTA-Stents",
                            "enrichedDescriptionShort": "Stents",
                            "preferMaterialMasterData": false,
                            "materialMatched": true,
                            "useCustomsTariffNumberDescription": true,
                            "useLineItemDescriptionShortening": true,
                            "materialMatchedByTariffNumber": true,
                            "materialMatchedByMaterialNr": false
                          }`}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <Form layout="vertical">
                      <Form.Item
                        label={t('Format String')}
                        tooltip={t(
                          'The format string for the resulting description of line items.',
                        )}
                      >
                        <Input
                          value={
                            value?.dataNormalization?.descriptionFormatString
                          }
                          onChange={(e) =>
                            onValueChange({
                              ...value,
                              dataNormalization: {
                                ...(value.dataNormalization || {}),
                                descriptionFormatString: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Form>
                    <div>
                      <h3>{t('Custom Formatting Options')}</h3>
                      <p>
                        {t(
                          'Customize the presentation of your line items with the following variables. Combine them with optional separators for a tailored display.',
                        )}
                      </p>
                      <dl>
                        <dt>
                          <code>{'{line-item-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'The standard description extracted from your document. This field is always populated.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{line-item-description-short}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'A shortened version of the line item description. Applied only if the original contains more than four words.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{material-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'The description from the matched material in your master data. This is based on material number or tariff number. If no match is found, or if matching is disabled, this will remain empty.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{enriched-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'Displays the material description if available; otherwise, it defaults to the line item description.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{enriched-description-short}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'Shows the short material description if available; otherwise, it presents a shortened line item description.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{tariff-number-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'The description associated with the matched tariff number. Empty if no match is found or if tariff number matching is disabled.',
                          )}
                        </dd>
                      </dl>
                      <p>
                        {t(
                          'Combine these variables with a separator enclosed in brackets to format your line item descriptions effectively. The separator is only added if the preceding variable contains data.',
                        )}
                      </p>
                      <h4>{t('Examples')}:</h4>
                      <ul>
                        <li>
                          <code>
                            {
                              '{tariff-number-description}[ - ]{enriched-description}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>
                            {
                              '{tariff-number-description}[ - ]{enriched-description-short}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>
                            {
                              '{tariff-number-description}[ - ]{line-item-description}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>
                            {
                              '{tariff-number-description} - {line-item-description-short}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>{'{enriched-description}'}</code>
                        </li>
                        <li>
                          <code>{'{enriched-description-short}'}</code>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </Card>
            )}
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.eliminateArticleNumberFromDescription
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    eliminateArticleNumberFromDescription: e.target.checked,
                  },
                })
              }
            >
              {t('Eliminate article number from item description')}
              <DCTooltip
                text={t(
                  'If you activate this, Digicust will remove the article number from the item description. If you deactivate this, Digicust will keep the article number in the item description.',
                )}
              />
            </Checkbox>
          </div>

          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Document Type Codes')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.documentCodeEnrichment
                  ?.addNegativeCodings
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    documentCodeEnrichment: {
                      ...(value.dataNormalization?.documentCodeEnrichment ||
                        {}),
                      addNegativeCodings: e.target.checked,
                    },
                  },
                })
              }
            >
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginTop: -20, marginBottom: -8 }}
              />
              {t('Automatically add negative codings')}
              <DCTooltip
                text={t(
                  'If you activate this, Digicust will always assume negative codings (Yxxx) necessary per customs tariff number and add them to goods.',
                )}
              />
            </Checkbox>
            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Blacklist codes')}
              <DCTooltip
                text={t(
                  `If you don't want certain codes to be used, you can blacklist them here.`,
                )}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                style={{ minWidth: '150px' }}
                mode="tags"
                placeholder={t('Add blacklist code')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.documentCodeEnrichment
                    ?.blacklistCodes || []
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      documentCodeEnrichment: {
                        ...(value.dataNormalization?.documentCodeEnrichment ||
                          {}),
                        blacklistCodes: e,
                      },
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase?.()
                    ?.includes?.(input?.toLowerCase?.())
                }
              ></Select>
            </div>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.documentCodeEnrichment
                  ?.noLineItemDocumentCodes
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    documentCodeEnrichment: {
                      ...(value.dataNormalization?.documentCodeEnrichment ||
                        {}),
                      noLineItemDocumentCodes: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('No document codes on line items')}
              <DCTooltip
                text={t(
                  'If you activate this, Digicust will remove all document codes from the line items.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.defaultInvoiceType === 'proforma'
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    defaultInvoiceType: e.target.checked
                      ? 'proforma'
                      : undefined,
                  },
                })
              }
            >
              {t('Assume proforma invoice')}
              <DCTooltip
                text={t(
                  'Will assume that uploaded invoice is a proforma invoice.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.waybillCodePreference === 'singleCode'
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    waybillCodePreference: e.target.checked
                      ? 'singleCode'
                      : 'seperateCodes',
                  },
                })
              }
            >
              {t('Prefer single waybill code')}
              <DCTooltip
                text={t(
                  'Specify whether Digicust should use a single waybill code (e.g. only 7HHF) or separate waybill codes (e.g. 7HHF and N730).',
                )}
              />
            </Checkbox>
          </div>

          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Additional Costs')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.foreignFreightCostRate !== undefined
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    foreignFreightCostRate: e.target.checked ? 0 : undefined,
                  },
                })
              }
            >
              {t('Fixed foreign freight cost rate')}
              <DCTooltip
                text={t(
                  'Digicust usually deducts the foreign freight cost and the domestic freight cost by measuring the distance between the sender and the recipient. If you activate this, Digicust will use a fixed rate instead.',
                )}
              />
            </Checkbox>
            {value.dataNormalization?.foreignFreightCostRate !== undefined && (
              <Card style={cardBorderCss}>
                <Form layout="vertical">
                  <InputNumber
                    suffix="%"
                    min={0}
                    max={100}
                    step={1}
                    value={Math.round(
                      value.dataNormalization?.foreignFreightCostRate * 100,
                    )}
                    onChange={(val) =>
                      onValueChange({
                        ...value,
                        dataNormalization: {
                          ...(value.dataNormalization || {}),
                          foreignFreightCostRate: val
                            ? Math.round(val) / 100
                            : undefined,
                        },
                      })
                    }
                  />
                </Form>
              </Card>
            )}
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.freightCostCalculation?.active}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    freightCostCalculation: {
                      ...(value.dataNormalization?.freightCostCalculation ||
                        {}),
                      active: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Calculate Freight Costs')}
              <DCTooltip
                text={t(
                  'Specify how Digicust calculates freight costs. For example estimate the freight cost based on weight information.',
                )}
              />
            </Checkbox>
            {value?.dataNormalization?.freightCostCalculation?.active && (
              <Card style={cardBorderCss}>
                <Row>
                  <Col span={12}>
                    <Form.Item label={t('Strategy')}>
                      <Select
                        style={{
                          width: '260px',
                        }}
                        size="middle"
                        popupMatchSelectWidth={false}
                        placeholder={t('Freight Cost Calculation Strategy')}
                        value={
                          value.dataNormalization?.freightCostCalculation
                            ?.strategy || 'default'
                        }
                        onChange={(e) =>
                          onValueChange({
                            ...value,
                            dataNormalization: {
                              ...(value.dataNormalization || {}),
                              freightCostCalculation: {
                                ...(value.dataNormalization
                                  ?.freightCostCalculation || {}),
                                strategy: e,
                              },
                            },
                          })
                        }
                      >
                        <Select.Option
                          title={t(
                            'Digicust will try to find freight costs in the uploaded documents only.',
                          )}
                          value="default"
                        >
                          {t('Default')}
                        </Select.Option>
                        <Select.Option
                          title={t(
                            'Digicust will assume freight costs per package.',
                          )}
                          value="packageDependent"
                        >
                          {t('Dependent on Packages')}
                        </Select.Option>
                        <Select.Option
                          title={t(
                            'Calculate freight cost based on weight information using the following logic: if weight is less than or equal to 10 kg, the cost is 22; if weight is less than or equal to 100 kg, the cost is weight multiplied by 2.5; if weight is less than or equal to 250 kg, the cost is weight multiplied by 2; if weight is less than or equal to 2000 kg, the cost is weight multiplied by 1.5; otherwise, the cost is 3000.',
                          )}
                          value="austriaWeightDependent"
                        >
                          {t('Weight Dependent (Austria/E-Zoll compatible)')}
                        </Select.Option>
                        <Select.Option
                          title={t(
                            'Calculate freight cost based on the following formula: (Package count * weight) + (km*0,20€)',
                          )}
                          value="distanceDependent"
                        >
                          {t('Distance dependent')}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {value?.dataNormalization?.freightCostCalculation
                    ?.strategy === 'packageDependent' && (
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexWrap: createCompany === true ? 'wrap' : 'nowrap',
                        alignItems: 'start',
                      }}
                    >
                      <label
                        style={{
                          height: '32px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          width: '100px',
                        }}
                      >
                        {t('Package Price :')}
                      </label>
                      <Form.Item>
                        <AmountOfMoneyInput
                          value={
                            value?.dataNormalization?.freightCostCalculation
                              ?.packagePrice
                          }
                          onChange={(e) =>
                            onValueChange({
                              ...value,
                              dataNormalization: {
                                ...(value.dataNormalization || {}),
                                freightCostCalculation: {
                                  ...(value.dataNormalization
                                    ?.freightCostCalculation || {}),
                                  packagePrice: e || undefined,
                                },
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={12}>
                    <Form.Item label={t('Apply')}>
                      <Select
                        size="middle"
                        style={{
                          width: '260px',
                          marginLeft:
                            value?.dataNormalization?.freightCostCalculation
                              ?.strategy === 'packageDependent' &&
                            createCompany === false
                              ? '14px'
                              : '0',
                        }}
                        popupMatchSelectWidth={false}
                        placeholder={t('Freight Cost Calculation Priority')}
                        value={
                          value.dataNormalization?.freightCostCalculation
                            ?.priority || 'fallback'
                        }
                        onChange={(e) =>
                          onValueChange({
                            ...value,
                            dataNormalization: {
                              ...(value.dataNormalization || {}),
                              freightCostCalculation: {
                                ...(value.dataNormalization
                                  ?.freightCostCalculation || {}),
                                priority: e,
                              },
                            },
                          })
                        }
                      >
                        <Select.Option value="fallback">
                          {t('Fallback (Apply only if not found otherwise)')}
                        </Select.Option>
                        <Select.Option value="override">
                          {t('Override')}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            )}

            <div
              className="hoverable"
              style={{ display: 'inline-block', marginRight: '10px' }}
            >
              {t('Additional cost strategy')}
              <DCTooltip
                text={t(
                  'Additional costs (e.g. transportation or packaging) can be added to the first line item or split to all line items equally. Customs offices usually accept both, but adding the additional costs to the first line item makes it easier to check.',
                )}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                placeholder={t('Additional cost strategy')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.additionalCostsStrategy ||
                  'equalDistribution'
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      additionalCostsStrategy: e,
                    },
                  })
                }
              >
                <Select.Option value="equalDistribution">
                  {t('Equal distribution')}
                </Select.Option>
                <Select.Option value="ignore">
                  {t('Ignore additional costs')}
                </Select.Option>
                <Select.Option value="addToFirstItem">
                  {t('Add to first line item')}
                </Select.Option>
                <Select.Option value="splitToAllItems">
                  {t('Split to all line items equally')}
                </Select.Option>
                <Select.Option value="splitToAllItemsByNetWeight">
                  {t('Split to all line items by net weight')}
                </Select.Option>
                <Select.Option value="splitToAllItemsByTotalValue">
                  {t('Split to all line items by value')}
                </Select.Option>
              </Select>
            </div>
          </div>

          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Packages')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.preferPackagesFromHead}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    preferPackagesFromHead: e.target.checked,
                  },
                })
              }
            >
              {t('Prefer packages from head')}
              <DCTooltip
                text={t(
                  'Prefers packages from invoice head over packages from line items.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.enforcePackageType !== undefined
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    enforcePackageType: e.target.checked
                      ? {
                          type: 'Package',
                          germanType: 'Packung/Packstück',
                          code: 'PK',
                          NC_40000: 'PK',
                        }
                      : undefined,
                  },
                })
              }
            >
              {t('Enforce package type')}
              <DCTooltip
                text={t(
                  'In case you want to enforce a specific package type, you can do so here. E.g. if you want to enforce that all packages are pallets, you can do so here.',
                )}
              />
            </Checkbox>
            {value?.dataNormalization?.enforcePackageType !== undefined && (
              <Card style={cardBorderCss}>
                <PackageTypeSelect
                  value={value.dataNormalization?.enforcePackageType}
                  onChange={(e) =>
                    onValueChange({
                      ...value,
                      dataNormalization: {
                        ...(value.dataNormalization || {}),
                        enforcePackageType: e || undefined,
                      },
                    })
                  }
                />
              </Card>
            )}
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.useQuantityAsPackageCount}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    useQuantityAsPackageCount: e.target.checked,
                  },
                })
              }
            >
              {t('Use item quantity as package count')}
              <DCTooltip
                text={t(
                  'Often, the quantity of items is the same as the number of packages. Activate, if you want to use the item quantity as package count. E.g. 50 pieces = 50 packages.',
                )}
              />
            </Checkbox>
          </div>
          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Export')}
          </Typography.Paragraph>
          <div>
            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Statistical value rounding')}
              <DCTooltip
                text={t('Specifies how the statistical value is rounded.')}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                placeholder={t('Statistical value rounding')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.statisticalValueRounding ||
                  'roundOff'
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      statisticalValueRounding: e,
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option value="noRounding">
                  {t('No rounding')}
                </Select.Option>
                <Select.Option value="roundOff">{t('Round off')}</Select.Option>
                <Select.Option value="roundUp">{t('Round up')}</Select.Option>
                <Select.Option value="roundToNearest">
                  {t('Round to nearest')}
                </Select.Option>
              </Select>
            </div>
          </div>
          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Transit')}
          </Typography.Paragraph>
          <div>
            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Guarantee value strategy')}
              <DCTooltip
                text={t(
                  'Specify how the guarantee value is calculated. If you choose "Header only; from Invoices Items", the guarantee value will be calculated based on the total value of the invoice items. If you choose "Header only; from Invoices Total Value", the guarantee value will be calculated based on the total value of the invoice. If you choose "Line Items", the guarantee value will be calculated based on the total value of the line items.',
                )}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                placeholder={t('Guarantee Value Strategy')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.guaranteeValueStrategy || 'lineItems'
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      guaranteeValueStrategy: e,
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option value="headerFromInvoices">
                  {t('Header only; from Invoices Items')}
                </Select.Option>
                <Select.Option value="headerFromInvoiceHeader">
                  {t('Header only; from Invoices Total Value')}
                </Select.Option>
                <Select.Option value="lineItems">
                  {t('Line Items')}
                </Select.Option>
              </Select>
            </div>

            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Guarantee value calculation')}
              <DCTooltip
                text={t(
                  'Specify how the guarantee value is calculated. If you choose "Exclude VAT", the guarantee value will be calculated without VAT. If you choose "Include 19% VAT", the guarantee value will be calculated with 19% VAT.',
                )}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                placeholder={t('Guarantee value calculation')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.guaranteeValueCalculation ||
                  'excludeVAT'
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      guaranteeValueCalculation: e,
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option value="excludeVAT">
                  {t('Exclude VAT')}
                </Select.Option>
                <Select.Option value="includeVAT19%">
                  {t('Include 19% VAT')}
                </Select.Option>
              </Select>
            </div>

            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Guarantee value calculation')}
              <DCTooltip
                text={t(
                  'Specify how the guarantee value is calculated. If you choose "Exclude VAT", the guarantee value will be calculated without VAT. If you choose "Include 19% VAT", the guarantee value will be calculated with 19% VAT.',
                )}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                placeholder={t('Guarantee value calculation strategy')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.guaranteeValueCalculationStrategy ||
                  'default'
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      guaranteeValueCalculationStrategy: e,
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option value="default">
                  {t('Default (Digicust tries to minimize duty paid)')}
                </Select.Option>
                <Select.Option value="assume25%">
                  {t('Always assume 25%')}
                </Select.Option>
              </Select>
            </div>
          </div>

          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Preference')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.checkTradePreferenceForWording}
              value={value?.dataNormalization?.checkTradePreferenceForWording}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    checkTradePreferenceForWording: e.target.checked,
                  },
                })
              }
            >
              {t('Check trade preference for wording')}
              <DCTooltip
                text={t(
                  'If you activate this, Digicust will check the wording of the trade preference and will not use the trade preference if the wording is not correct. If you deactivate this, Digicust will use the trade preference even if the wording is not correct. Many clients choose to deactivate this to make use of the trade preference even if the wording is not correct. Others might choose to activate this to make sure that the trade preference is only used if the wording is correct.',
                )}
              />
            </Checkbox>

            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Assume origin from preference')}
              <DCTooltip
                text={t(`If the country of origin is not explicitly stated on documents
                  (e.g. invoice), Digicust will assume the country of origin
                  from the trade preference. You can disable this option here.`)}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                placeholder={t('Assume Origin from Preference')}
                optionFilterProp="children"
                value={
                  value.dataNormalization?.doNotAssumeOriginFromPreference ||
                  false
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      doNotAssumeOriginFromPreference: e,
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option value={true}>
                  {t('Do not assume origin')}
                </Select.Option>
                <Select.Option value={false}>
                  {t('Assume origin')}
                </Select.Option>
              </Select>
            </div>
            <div className="hoverable" style={{ display: 'inline-block' }}>
              {t('Assume origin from head')}
              <DCTooltip
                text={t(`If the country of origin is not explicitly stated on line item
                  level (e.g. invoice), Digicust can assume the country of
                  origin from the aggregated head.`)}
              />
              <Select
                popupMatchSelectWidth={false}
                size="small"
                showSearch
                placeholder={t('Assume Origin from Head')}
                optionFilterProp="children"
                value={value.dataNormalization?.assumeOriginFromHead || false}
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataNormalization: {
                      ...(value.dataNormalization || {}),
                      assumeOriginFromHead: e,
                    },
                  })
                }
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Select.Option value={true}>
                  {t('Assume origin from head')}
                </Select.Option>
                <Select.Option value={false}>
                  {t('Do not assume origin from head')}
                </Select.Option>
              </Select>
            </div>
          </div>
          <Typography.Paragraph style={{ marginTop: '30px' }} strong>
            {t('Other options')}
          </Typography.Paragraph>
          <div>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.ignoreTotalValueFromProformaInvoices
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    ignoreTotalValueFromProformaInvoices: e.target.checked,
                  },
                })
              }
            >
              {t('Ignore total value from proforma invoices')}
              <DCTooltip
                text={t(
                  'If both trade invoices and proforma invoices are given, only total values from trade invoices are used and proforma values ignored.',
                )}
              />
            </Checkbox>

            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.emailSenderAsRepresentativeEmail
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    emailSenderAsRepresentativeEmail: e.target.checked,
                  },
                })
              }
            >
              {t('Email sender as representative email')}
              <DCTooltip
                text={t('Sets the representative email to email sender.')}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={value.dataNormalization?.useLineItemDescription}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    useLineItemDescription: e.target.checked,
                  },
                })
              }
            >
              {t('Use line item description')}
              <DCTooltip text={t('')} />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={value.dataNormalization?.allowDateInOrderNumber}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    allowDateInOrderNumber: e.target.checked,
                  },
                })
              }
            >
              {t('Allow date in order number')}
              <DCTooltip
                text={t(
                  'If you activate this, Digicust will allow dates in the order number. If you deactivate this, Digicust will remove them.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.useSpecializedDescriptionAggregation
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    useSpecializedDescriptionAggregation: e.target.checked,
                  },
                })
              }
            >
              {t('Use specialized description aggregation')}
              <DCTooltip
                text={t(
                  'Enables experimental feature to use specialized description aggregation for line items, summing up quantities and concatenating lineItemDescriptions with "-" in the special "aggregated.aggregatedDescriptions" map (otherwise this property will remain empty or undefined)',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value.dataNormalization?.removeStakeholderContactInformation
                  ?.active
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    removeStakeholderContactInformation: {
                      ...(value.dataNormalization
                        ?.removeStakeholderContactInformation || {}),
                      active: e.target.checked,
                    },
                  },
                })
              }
            >
              {t('Remove stakeholder contact')}
              <DCTooltip
                text={t(
                  'If you activate this, Digicust will remove any stakeholder contact information (contact person, email, phone number) we find on documents.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={!!value.dataNormalization?.defaultLicensePlate}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    defaultLicensePlate: e.target.checked ? 'german' : false,
                  },
                })
              }
            >
              {t('Default license plate')}
              <DCTooltip
                text={t(
                  "Will set the license plate to default value, if not specified otherwise (e.g. 'FLUGZEUG' or 'LKW'.",
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.ignoreLineItemsFromInvoice}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    ignoreLineItemsFromInvoice: e.target.checked,
                  },
                })
              }
            >
              {t('Ignore line items from invoice')}
              <DCTooltip
                text={t(`Digicust will ignore line items from invoice. This comes
                    handy when information comes from other sources (e.g. Excel
                    documents).`)}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.ignoreLineItemsWithoutInvoice}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    ignoreLineItemsWithoutInvoice: e.target.checked,
                  },
                })
              }
            >
              {t('Ignore line items without invoice')}
              <DCTooltip
                text={t(
                  'Digicust will ignore line items without invoice. This is for if line items come from transit documents and a new declaration is to be created for only one or few of the line items we actually have invoices for.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={value?.dataNormalization?.forceInvoiceNumberToLRN}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    forceInvoiceNumberToLRN: e.target.checked,
                  },
                })
              }
            >
              {t('Use invoice number as LRN')}
              <DCTooltip
                text={t('Digicust will use the invoice number as LRN.')}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.forceInvoiceNumberToReferenceNumberUCR
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    forceInvoiceNumberToReferenceNumberUCR: e.target.checked,
                  },
                })
              }
            >
              {t('Use invoice number as reference number UCR')}
              <DCTooltip
                text={t(
                  'Digicust will use the invoice number as reference number UCR (AES3).',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization
                  ?.forceInvoiceNumberToRegistrationNumberExternal
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    forceInvoiceNumberToRegistrationNumberExternal:
                      e.target.checked,
                  },
                })
              }
            >
              {t('Use invoice number as registration number external')}
              <DCTooltip
                text={t(
                  'Digicust will use the invoice number as registration number external (AES3).',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization
                  ?.alwaysAssumeThreeDigitDecimalForWeights
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    alwaysAssumeThreeDigitDecimalForWeights: e.target.checked,
                  },
                })
              }
            >
              {t('Always assume 3 digit decimal for weights')}
              <DCTooltip
                text={t(
                  'Will explicitly assume 3 digit decimals for weights (even if unclear whether dot or comma is thousand separator or decimal separator.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.alwaysAssumeThousandsForWeights
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    alwaysAssumeThousandsForWeights: e.target.checked,
                  },
                })
              }
            >
              {t('Always assume thousands for weight')}
              <DCTooltip
                text={t(
                  'Will explicitly assume thousands if > 3 digits in a weight measure.',
                )}
              />
            </Checkbox>
            <Checkbox
              className="hoverable"
              checked={
                value?.dataNormalization?.alwaysAssumeThousandsForQuantity
              }
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataNormalization: {
                    ...(value.dataNormalization || {}),
                    alwaysAssumeThousandsForQuantity: e.target.checked,
                  },
                })
              }
            >
              {t('Always assume thousands for quantity')}
              <DCTooltip
                text={t(
                  'Will explicitly assume thousands if > 3 digits in a quantity measure.',
                )}
              />
            </Checkbox>
          </div>
        </Space>
      )}
    </CardWrapper>
  );
};
