import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// TODO this can also be normal debounced input
export const SearchInput = ({
  onChange,
  suffix,
  style,
  onType,
}: {
  onChange?: (value: string) => void;
  suffix?: React.ReactNode;
  style?: React.CSSProperties;
  onType?: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  return (
    <Input
      style={style}
      variant="filled"
      suffix={suffix}
      allowClear={true}
      prefix={<SearchOutlined />}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onType && onType(e.target.value);
      }}
      onBlur={() => onChange && onChange(value)}
      placeholder={t('Search')}
    />
  );
};
