import { Select, Typography } from 'antd';
import {
  ConstellationOfParticipantsModel,
  RepresentativeConstellation,
} from 'digicust_types';
import { useTranslation } from 'react-i18next';

const ConstellationOfParticipantsSelect = ({
  value,
  onChange = () => null,
}: {
  value?: ConstellationOfParticipantsModel;
  onChange?: (value?: ConstellationOfParticipantsModel) => void;
}) => {
  const { t } = useTranslation();

  const options: (ConstellationOfParticipantsModel & { label: string })[] = [
    {
      label: t('No Representation'),
      DE_A0127: '0000',
      DE_A1770: '0',
      value: RepresentativeConstellation.None,
    },
    {
      label: t('Direct Representation'),
      DE_A0127: '0010',
      DE_A1770: '1',
      value: RepresentativeConstellation.Direct,
    },
    {
      label: t('Indirect Representation'),
      DE_A0127: '1000',
      DE_A1770: '2',
      value: RepresentativeConstellation.Indirect,
    },
  ];

  return (
    <Select
      variant="filled"
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Constellation')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.DE_A0127}</b> {item?.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default ConstellationOfParticipantsSelect;
