import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Space, Typography } from 'antd';

import { Rule } from 'digicust_types';
import { ReactNode, useState } from 'react';
import EditInformationModal from '../../../../Components/Inputs/EditInformationModal';
import AddToCatalogButton from './Catalog/AddToCatalogButton';
import ConditionBlock from './Components/ConditionBlock';
import ThenBlockCard from './Components/ThenBlockCard';
import styles from './Rule.module.css';

const RuleComponent = ({
  value,
  onCopy,
  onDelete,
  onValueChange,
  moveComponent,
}: {
  value: Rule;
  onValueChange?: (value: Rule) => void;
  onDelete?: () => void;
  onCopy?: () => void;
  moveComponent: ReactNode;
}) => {
  const [openStatus, setOpenStatus] = useState(false);

  return (
    <>
      <Space
        direction="vertical"
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        <Typography.Title
          level={5}
          style={{ margin: 25 }}
          editable={
            onValueChange
              ? {
                  autoSize: false,
                  onChange: (name) => {
                    onValueChange({ ...value, name });
                  },
                }
              : false
          }
        >
          {value?.name}
        </Typography.Title>
        <ConditionBlock
          value={value}
          onChange={onValueChange ? onValueChange : () => null}
        />
        {/* <ArrowRightOutlined className={styles.ArrowRight} /> */}
        <ArrowDownOutlined style={{ marginLeft: 20 }} />
        <ThenBlockCard
          value={value}
          onChange={onValueChange ? onValueChange : () => null}
          openInformationModal={() => setOpenStatus(true)}
        />
      </Space>

      <EditInformationModal
        value={value}
        openStatus={openStatus}
        onCancel={() => setOpenStatus(false)}
        onChange={onValueChange ? onValueChange : () => null}
      />
    </>
  );
};

export default RuleComponent;
