import { Button, Checkbox, Form, Input, message } from 'antd';
import axios from 'axios';
import { AebSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function AEBSystemForm({
  config,
  changeData,
}: {
  config: AebSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('Client Ident Code')}>
        <Input
          variant="filled"
          placeholder={t('Client Ident Code')}
          value={config?.['client-ident-code']}
          onChange={(e) => changeData('client-ident-code', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Client Name')}>
        <Input
          variant="filled"
          placeholder={t('Client Name')}
          value={config?.['client-name']}
          onChange={(e) => changeData('client-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Client System ID')}>
        <Input
          variant="filled"
          placeholder={t('Client System ID')}
          value={config?.['client-system-id']}
          onChange={(e) => changeData('client-system-id', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Endpoint')}>
        <Input
          variant="filled"
          placeholder={t('Endpoint')}
          value={config?.['endpoint']}
          onChange={(e) => changeData('endpoint', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('User Name')}>
        <Input
          variant="filled"
          placeholder={t('User Name')}
          value={config?.['user-name']}
          onChange={(e) => changeData('user-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          variant="filled"
          placeholder={t('Password')}
          value={config?.['password']}
          onChange={(e) => changeData('password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Profile')}>
        <Input
          variant="filled"
          placeholder={t('Profile')}
          value={config?.['profile']}
          onChange={(e) => changeData('profile', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Organizational Unit')}>
        <Input
          variant="filled"
          placeholder={t('Organizational Unit')}
          value={config?.['organizationalUnit']}
          onChange={(e) => changeData('organizationalUnit', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Aggregate Items')}>
        <Checkbox
          checked={config?.aggregateItems}
          onChange={(e) => changeData('aggregateItems', e.target.checked)}
        />
      </Form.Item>
      <Form.Item label={t('Attach Documents')}>
        <Checkbox
          checked={config?.['attachDocuments']}
          onChange={(e) => changeData('attachDocuments', e.target.checked)}
        />
      </Form.Item>
      {/* <Button
        onClick={async () => {
          try {
            const response = await axios.post(
              `${config?.['endpoint']}/rest/logon/user`,
              {
                clientName: config?.['client-name'],
                userName: config?.['user-name'],
                password: config?.['password'],
                isExternalLogon: true,
                localeName: 'en',
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              },
            );
            const { token } = response.data;
            if (response.status === 200 && token) {
              message.success('AEB Authentication successful');
            } else {
              message.error(
                'AEB Authentication failed: ' +
                  response.status +
                  ' - ' +
                  response.data,
              );
            }
          } catch (error: any) {
            message.error('AEB Authentication failed: ' + error.message);
          }
        }}
      >
        {t('Test Connection')}
      </Button> */}
    </Form>
  );
}
