import { Form, Input } from 'antd';
import { CargoSoftSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function CargoSoftSystemForm({
  config,
  changeData,
}: {
  config: CargoSoftSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('DNS Name')}>
        <Input
          variant="filled"
          placeholder={t('DNS Name')}
          value={config?.['sftp-dns-name']}
          onChange={(e) => changeData('sftp-dns-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Port')}>
        <Input
          variant="filled"
          placeholder={t('Port')}
          value={config?.['sftp-port']}
          onChange={(e) => changeData('sftp-port', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Login name')}>
        <Input
          variant="filled"
          placeholder={t('Login name')}
          value={config?.['sftp-login-name']}
          onChange={(e) => changeData('sftp-login-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          variant="filled"
          placeholder={t('Password')}
          value={config?.['sftp-password']}
          onChange={(e) => changeData('sftp-password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Inbound')}>
        <Input
          variant="filled"
          placeholder={t('Inbound')}
          value={config?.['sftp-inbound']}
          onChange={(e) => changeData('sftp-inbound', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Sender ID')}>
        <Input
          variant="filled"
          placeholder={t('Sender ID')}
          value={config?.['sender-id']}
          onChange={(e) => changeData('sender-id', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Receiver ID')}>
        <Input
          variant="filled"
          placeholder={t('Receiver ID')}
          value={config?.['receiver-id']}
          onChange={(e) => changeData('receiver-id', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Company Code')}>
        <Input
          variant="filled"
          placeholder={t('Company Code')}
          value={config?.['company-code']}
          onChange={(e) => changeData('company-code', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Office Code')}>
        <Input
          variant="filled"
          placeholder={t('Office Code')}
          value={config?.['office-code']}
          onChange={(e) => changeData('office-code', e.target.value)}
        />
      </Form.Item>
    </Form>
  );
}
