import { Button, Modal, Space, Tabs, Typography, message } from 'antd';
import {
  ExecutionStrategy,
  LineItemModel,
  Rule,
  ValidationError,
} from 'digicust_types';
import LineItemsTable from '../../Pages/CustomsCaseDetails/line-items-tab';
import StakeholderDocumentInput from '../../Pages/CustomsCaseDetails/stakeholder-tab/StakeholderDocumentInput';
import ExecutionStrategyComponent from '../../Pages/Settings/ExecutionStrategies/ExecutionStrategyDetails';
import ContainersInput from './ContainersInput';
import CustomValidationMessagesComponent from './CustomValidationMessagesComponent ';
import CustomsOffices from './CustomsOffices';
import FreightDocumentInput from './FreightDocumentInput';
import GeneralDetailsInput from './GeneralDetailsInput';
import MeansOfTransportation from './MeansOfTransportationInput';
import { useTranslation } from 'react-i18next';
import PackagesInput from './PackagesInput';

const EditInformationModal = ({
  onChange,
  value,
  openStatus,
  onCancel,
}: {
  value: Rule | null;
  onChange: (e: Rule) => void;
  openStatus: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        centered
        open={openStatus}
        onCancel={onCancel}
        closable
        width={1000}
        styles={{ body: { height: '800px' } }}
        footer={null}
        destroyOnClose={true}
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: t('General'),
              key: 'general',
              children: (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 270px)',
                  }}
                >
                  <GeneralDetailsInput
                    onLineItemsChange={() => {
                      console.log('Not implemented');
                    }}
                    value={value?.userInput?.annotatedAggregated}
                    onChange={(generalItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            ...(generalItem || {}),
                          },
                        },
                      });
                    }}
                  />

                  <CustomsOffices
                    value={
                      value?.userInput?.annotatedAggregated?.customsOffices ||
                      []
                    }
                    onValueChange={(offices) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            customsOffices: offices || [],
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Stakeholders'),
              key: 'stakeholder',
              children: (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 270px)',
                  }}
                >
                  <StakeholderDocumentInput
                    value={value?.userInput?.annotatedAggregated}
                    onChange={(stakeholderItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            ...(stakeholderItem || {}),
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Shipment'),
              key: 'shipment',
              children: (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 270px)',
                  }}
                >
                  <FreightDocumentInput
                    caseData={value?.userInput?.annotatedAggregated || {}}
                    updateItem={(freightItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            ...(freightItem || {}),
                          },
                        },
                      });
                    }}
                  />
                  <PackagesInput
                    value={
                      value?.userInput?.annotatedAggregated?.packages || []
                    }
                    onValueChange={(packages) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            packages: packages || [],
                          },
                        },
                      });
                    }}
                  />
                  <MeansOfTransportation
                    value={
                      value?.userInput?.annotatedAggregated
                        ?.meansOfTransportation || []
                    }
                    onValueChange={(transportationItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            meansOfTransportation: transportationItem || [],
                          },
                        },
                      });
                    }}
                  />
                  <ContainersInput
                    value={
                      value?.userInput?.annotatedAggregated?.containers || []
                    }
                    onValueChange={(transportationItem) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            containers: transportationItem || [],
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Line Items'),
              key: 'lineItems',
              children: (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 270px)',
                  }}
                >
                  <Typography.Text>
                    {t(
                      'These changes will be applied to all matching line items.',
                    )}
                  </Typography.Text>
                  <Typography.Text>
                    {Object.keys(value?.userInput?.annotatedLineItem || {})
                      .length > 0 && (
                      <Space direction="horizontal">
                        <span>{`${
                          Object.keys(value?.userInput?.annotatedLineItem || {})
                            .length
                        } ${t('changes')}.`}</span>
                        <Button
                          onClick={() => {
                            onChange({
                              ...(value || {}),
                              userInput: {
                                ...(value?.userInput || {}),
                                annotatedLineItem: {},
                              },
                            });
                            message.info(
                              t(
                                'Cleared all changes. To render correctly, re-open the tab.',
                              ),
                            );
                          }}
                        >
                          {t('Clear')}
                        </Button>
                      </Space>
                    )}
                  </Typography.Text>
                  <LineItemsTable
                    single
                    lineItems={
                      value?.userInput?.annotatedLineItem
                        ? [value?.userInput?.annotatedLineItem]
                        : [{}]
                    }
                    setLineItems={(lineItems: LineItemModel[]) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedLineItem: {
                            ...(value?.userInput?.annotatedLineItem || {}),
                            ...lineItems[0],
                          },
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Execution Strategy'),
              key: 'executionStrategy',
              children: (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 270px)',
                  }}
                >
                  <ExecutionStrategyComponent
                    noAlias={true}
                    value={
                      value?.userInput?.annotatedExecutionStrategy ||
                      ({} as ExecutionStrategy)
                    }
                    onValueChange={(executionStrategy) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedExecutionStrategy: executionStrategy,
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
            {
              label: t('Custom Validation Messages'),
              key: 'customValidationMessages',
              children: (
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 270px)',
                  }}
                >
                  <CustomValidationMessagesComponent
                    value={value?.userInput?.customValidationMessages || []}
                    onValueChange={(
                      customValidationMessages: ValidationError[],
                    ) => {
                      onChange({
                        ...(value || {}),
                        userInput: {
                          ...(value?.userInput || {}),
                          customValidationMessages: customValidationMessages,
                        },
                      });
                    }}
                  />
                </Space>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default EditInformationModal;
