import { Select, Typography } from 'antd';
import {
  CostIntersection,
  CostIntersectionTypes,
  ProcedureMode,
} from 'digicust_types';
import { useTranslation } from 'react-i18next';

const CostIntersectionSelect = ({
  value,
  onChange = () => null,
  procedure,
}: {
  procedure?: ProcedureMode;
  value?: CostIntersection;
  onChange?: (value?: CostIntersection) => void;
}) => {
  const { t } = useTranslation();

  const options =
    procedure === ProcedureMode.import
      ? [
          {
            label: t('Outside Zone, Duty Unpaid'),
            value: CostIntersectionTypes.outsideZoneDutyUnpaid,
            NC_23000: 'A',
            DE_A1850: '1',
          },
          {
            label: t('Free Border, Duty Unpaid'),
            value: CostIntersectionTypes.freeBorderDutyUnpaid,
            NC_23000: 'B',
            DE_A1850: '3',
          },
          {
            label: t('Inside Zone, Duty Unpaid'),
            value: CostIntersectionTypes.insideZoneDutyUnpaid,
            NC_23000: 'C',
            DE_A1850: '3',
          },
          {
            label: t('Inside Zone, Duty Paid, Exclusive VAT'),
            value: CostIntersectionTypes.insideZoneDutyPaidExclusiveVat,
            NC_23000: 'I',
            DE_A1850: '3',
          },
        ]
      : [
          {
            label: t('Inside Zone, Duty Unpaid'),
            value: CostIntersectionTypes.insideZoneDutyUnpaid,
            NC_23000: 'C',
            DE_A1850: '1',
          },
          {
            label: t('Outside Zone, Duty Unpaid'),
            value: CostIntersectionTypes.outsideZoneDutyUnpaid,
            NC_23000: 'A',
            DE_A1850: '3',
          },
          {
            label: t('Free Border, Duty Unpaid'),
            value: CostIntersectionTypes.freeBorderDutyUnpaid,
            NC_23000: 'B',
            DE_A1850: '1',
          },
          {
            label: t('Outside Zone, Duty Paid, Exclusive VAT'),
            value: CostIntersectionTypes.outsideZoneDutyPaidExclusiveVat,
            NC_23000: 'G',
            DE_A1850: '3',
          },
        ];

  return (
    <Select
      variant="filled"
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Cost Intersection')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.DE_A1850}</b> {item.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CostIntersectionSelect;
