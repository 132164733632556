import { Select, Typography } from 'antd';
import { TypeOfExportDeclarationModel } from 'digicust_types';
import { useTranslation } from 'react-i18next';

const TypeOfExportDeclarationSelect = ({
  value,
  onChange = () => null,
}: {
  value?: TypeOfExportDeclarationModel;
  onChange?: (value?: TypeOfExportDeclarationModel) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      value: '00000100',
      DE_A0121: '00000100',
      label: t('Standard export declaration for the two-step normal procedure'),
    },
    {
      value: '00000110',
      DE_A0121: '00000110',
      label: t(
        'Simplified export declaration for the two-step normal procedure',
      ),
    },
    {
      value: '00000200',
      DE_A0121: '00000200',
      label: t(
        'Standard export declaration for the two-step normal procedure with application for presentation outside the office premises according to §12(4) AWV',
      ),
    },
    {
      value: '00000210',
      DE_A0121: '00000210',
      label: t(
        'Simplified export declaration for the two-step normal procedure with application for presentation outside the office premises according to §12(4) AWV',
      ),
    },
    {
      value: '00000400',
      DE_A0121: '00000400',
      label: t(
        'Standard export declaration for the two-step normal procedure using a CCL export authorization',
      ),
    },
    {
      value: '00000901',
      DE_A0121: '00000901',
      label: t(
        'Standard export declaration for the one-step procedure for goods with a value up to 3,000 EUR',
      ),
    },
    {
      value: '00000902',
      DE_A0121: '00000902',
      label: t(
        'Standard export declaration for the one-step procedure in justified cases',
      ),
    },
    {
      value: '00001300',
      DE_A0121: '00001300',
      label: t(
        'Standard export declaration for the two-step simplified procedure using an SDE export authorization',
      ),
    },
    {
      value: '00001310',
      DE_A0121: '00001310',
      label: t(
        'Simplified export declaration for the two-step simplified procedure using an SDE export authorization',
      ),
    },
    {
      value: '00001410',
      DE_A0121: '00001410',
      label: t(
        'Simplified export declaration for the two-step simplified procedure using SDE and CCL export authorizations',
      ),
    },
    {
      value: '00110100',
      DE_A0121: '00110100',
      label: t(
        'Standard export declaration for the two-step normal procedure using an OPO-PV authorization',
      ),
    },
    {
      value: '00110110',
      DE_A0121: '00110110',
      label: t(
        'Simplified export declaration for the two-step normal procedure using an OPO-PV authorization',
      ),
    },
    {
      value: '00110200',
      DE_A0121: '00110200',
      label: t(
        'Standard export declaration for the two-step normal procedure with application for presentation outside the office premises according to §12(4) AWV using an OPO-PV authorization',
      ),
    },
    {
      value: '00110210',
      DE_A0121: '00110210',
      label: t(
        'Simplified export declaration for the two-step normal procedure with application for presentation outside the office premises according to §12(4) AWV using an OPO-PV authorization',
      ),
    },
    {
      value: '00110400',
      DE_A0121: '00110400',
      label: t(
        'Standard export declaration for the two-step normal procedure using OPO-PV and CCL-PV authorizations',
      ),
    },
    {
      value: '00111300',
      DE_A0121: '00111300',
      label: t(
        'Standard export declaration for the two-step simplified procedure using SDE PV and OPO-PV authorizations',
      ),
    },
    {
      value: '00111310',
      DE_A0121: '00111310',
      label: t(
        'Simplified export declaration for the two-step simplified procedure using SDE-PV and OPO-PV authorizations',
      ),
    },
    {
      value: '00111410',
      DE_A0121: '00111410',
      label: t(
        'Simplified export declaration for the two-step simplified procedure using SDE-PV, OPO-PV, and CCL-PV authorizations',
      ),
    },
    {
      value: '00120100',
      DE_A0121: '00120100',
      label: t(
        'Standard export declaration for the two-step normal procedure with application for a simplified PV authorization',
      ),
    },
    {
      value: '00120110',
      DE_A0121: '00120110',
      label: t(
        'Simplified export declaration for the two-step normal procedure with application for a simplified PV authorization',
      ),
    },
    {
      value: '00120200',
      DE_A0121: '00120200',
      label: t(
        'Standard export declaration for the two-step normal procedure with applications for presentation outside the office premises according to §12(4) AWV and a simplified PV authorization',
      ),
    },
    {
      value: '00120210',
      DE_A0121: '00120210',
      label: t(
        'Simplified export declaration for the two-step normal procedure with applications for presentation outside the office premises according to §12(4) AWV and a simplified PV authorization',
      ),
    },
    {
      value: '00200100',
      DE_A0121: '00200100',
      label: t(
        'Standard export declaration for the two-step normal procedure as an economic PV',
      ),
    },
    {
      value: '00200110',
      DE_A0121: '00200110',
      label: t(
        'Simplified export declaration for the two-step normal procedure as an economic PV',
      ),
    },
    {
      value: '00200200',
      DE_A0121: '00200200',
      label: t(
        'Standard export declaration for the two-step normal procedure with application for presentation outside the office premises according to §12(4) AWV as an economic PV',
      ),
    },
    {
      value: '00200210',
      DE_A0121: '00200210',
      label: t(
        'Simplified export declaration for the two-step normal procedure with application for presentation outside the office premises according to §12(4) AWV as an economic PV',
      ),
    },
    {
      value: '00200400',
      DE_A0121: '00200400',
      label: t(
        'Standard export declaration for the two-step normal procedure as an economic PV using a CCL export authorization',
      ),
    },
    {
      value: '00201300',
      DE_A0121: '00201300',
      label: t(
        'Standard export declaration for the two-step simplified procedure as an economic PV using an SDE export authorization',
      ),
    },
    {
      value: '00201310',
      DE_A0121: '00201310',
      label: t(
        'Simplified export declaration for the two-step simplified procedure as an economic PV using an SDE export authorization',
      ),
    },
    {
      value: '00201410',
      DE_A0121: '00201410',
      label: t(
        'Simplified export declaration for the two-step simplified procedure as an economic PV using SDE and CCL export authorizations',
      ),
    },
    {
      value: '10000000',
      DE_A0121: '10000000',
      label: t('Retrospective export declaration'),
    },
    {
      value: '10000400',
      DE_A0121: '10000400',
      label: t(
        'Retrospective export declaration using a CCL export authorization',
      ),
    },
    {
      value: '10110000',
      DE_A0121: '10110000',
      label: t(
        'Retrospective export declaration using an OPO-PV authorization',
      ),
    },
    {
      value: '10110400',
      DE_A0121: '10110400',
      label: t(
        'Retrospective export declaration using OPO-PV and CCL-PV authorizations',
      ),
    },
    {
      value: '10200000',
      DE_A0121: '10200000',
      label: t('Retrospective export declaration as an economic PV'),
    },
    {
      value: '10200400',
      DE_A0121: '10200400',
      label: t(
        'Retrospective export declaration as an economic PV using a CCL export authorization',
      ),
    },
    {
      value: '11000000',
      DE_A0121: '11000000',
      label: t(
        'Subsequent export declaration for previous wholly or partially incorrect declaration',
      ),
    },
    {
      value: '11110000',
      DE_A0121: '11110000',
      label: t(
        'Subsequent export declaration for previous wholly or partially incorrect declaration using an OPO-PV authorization',
      ),
    },
    {
      value: '11120000',
      DE_A0121: '11120000',
      label: t(
        'Subsequent export declaration for previous wholly or partially incorrect declaration with application for a simplified PV authorization',
      ),
    },
    {
      value: '11200000',
      DE_A0121: '11200000',
      label: t(
        'Subsequent export declaration for previous wholly or partially incorrect declaration as an economic PV',
      ),
    },
    {
      value: '12000000',
      DE_A0121: '12000000',
      label: t('Subsequent export declaration from the emergency procedure'),
    },
    {
      value: '12110000',
      DE_A0121: '12110000',
      label: t(
        'Subsequent export declaration from the emergency procedure using an OPO-PV authorization',
      ),
    },
    {
      value: '12120000',
      DE_A0121: '12120000',
      label: t(
        'Subsequent export declaration from the emergency procedure with application for a simplified PV authorization',
      ),
    },
    {
      value: '12200000',
      DE_A0121: '12200000',
      label: t(
        'Subsequent export declaration from the emergency procedure as an economic PV',
      ),
    },
    {
      value: '13000000',
      DE_A0121: '13000000',
      label: t(
        'Retrospective export declaration according to Carnet ATA without re-import',
      ),
    },
    {
      value: '20000000',
      DE_A0121: '20000000',
      label: t(
        'Monthly summary declaration for the simplified procedure using an EIR export authorization',
      ),
    },
  ];

  return (
    <Select
      variant="filled"
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Type of Export Declaration')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.DE_A0121}</b> {item?.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default TypeOfExportDeclarationSelect;
