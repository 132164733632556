import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select, Space, Typography } from 'antd';
import { Package, PackageType } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DGTable from '../../common/DGTable';
import { StakeholderRepository } from '../../utils/repositories/stakeholder.repository';
import DebouncedInput from './Common/DebouncedInput';
import NumberInput from './Common/NumberInput';
import WeightInput from './WeightInput';

const PackagesInput = ({
  value,
  onValueChange,
}: {
  value: Package[];
  onValueChange: (value: Package[]) => void;
}) => {
  const updateItem = (document: Package, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      onValueChange(newValue);
    }
  };

  const [packagesList, setPackagesList] = useState<[]>();

  const getPackages = async () => {
    const response: any = await StakeholderRepository.getPackagesList('en');
    setPackagesList(response?.data?.packages || []);
  };

  useEffect(() => {
    getPackages();
  }, []);

  const { t } = useTranslation();

  return (
    <div>
      <DGTable<Package>
        showHeader={false}
        headerTitle={t('Packages')}
        scroll={{ x: '100%', y: 'calc(100vh - 300px)' }}
        dataSource={value || []}
        rightToolbar={
          <Button
            onClick={() => onValueChange([...value, {}])}
            key="button"
            icon={<PlusOutlined />}
          >
            {t('Add')}
          </Button>
        }
        columns={[
          {
            getValue: (document) => document?.type?.value,
            title: t('Type'),
            key: 'type',
            fixed: 'left',
            width: '150px',
            render: (_, item, index) => (
              <Select
                variant="filled"
                style={{ width: '100%' }}
                showSearch
                popupMatchSelectWidth={200}
                placeholder="Type"
                value={item?.type?.code}
                onChange={(code) => {
                  updateItem({ ...item, type: { code } }, index);
                }}
              >
                {packagesList?.map((item: PackageType, key) => (
                  <Select.Option
                    title={item?.germanType}
                    key={key}
                    value={item?.code}
                    label={item?.type}
                  >
                    <Space key={key}>
                      <Typography.Text
                        style={{ maxWidth: '400px', display: 'flex' }}
                        ellipsis
                      >
                        {item?.code} {item?.type}
                      </Typography.Text>
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            getValue: (document) => document?.amount?.value,
            title: t('Amount'),
            key: 'amount',
            width: '150px',
            render: (_, item, index) => (
              <NumberInput
                placeholder={t('Amount')}
                value={item?.amount?.value}
                onChange={(value) => {
                  updateItem(
                    {
                      ...(item || {}),
                      amount: { input: value?.toString(), value },
                    },
                    index,
                  );
                }}
              />
            ),
          },
          {
            getValue: (document) => document?.marks?.value,
            title: t('Package Name'),
            key: 'name',
            width: '150px',
            render: (_, item, index) => (
              <DebouncedInput
                placeholder={t('Package Name')}
                value={item?.marks?.value}
                onChange={(value) => {
                  updateItem(
                    { ...item, marks: { input: value, value } },
                    index,
                  );
                }}
              />
            ),
          },
          {
            getValue: (document) => document?.weight?.value,
            title: t('Weight'),
            key: 'weight',
            width: '150px',
            render: (_, item, index) => (
              <WeightInput
                weight={item.weight}
                onChange={(weight) =>
                  updateItem({ ...(item || {}), weight }, index)
                }
              />
            ),
          },
          {
            getValue: (document) => document?.netWeight?.value || '',
            title: t('Net Weight'),
            key: 'netWeight',
            width: '150px',
            render: (_, item, index) => (
              <WeightInput
                weight={item.netWeight}
                onChange={(netWeight) =>
                  updateItem({ ...(item || {}), netWeight }, index)
                }
              />
            ),
          },
          {
            title: 'Actions',
            key: 'actions',
            width: '30px',
            render: (_, item, index) => (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onValueChange(newValue);
                }}
              ></Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PackagesInput;
