import { ExportOutlined, WechatFilled } from '@ant-design/icons';
import { Button, Drawer, Flex, Typography } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ChatComponentImpl from '../Chat/ChatComponentImpl';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TariffClassification from './TariffClassification';

export default function TaricChatFloatingDrawer() {
  const { t } = useTranslation();

  const [showChat, setShowChat] = useState<boolean>(false);
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const customerId = pathSegments[1];
  const projectId = pathSegments[2];
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        open={showChat}
        onClose={() => setShowChat(false)}
        title={t('Tariff Classification')}
        footer={null}
        width={700}
        styles={{
          body: { padding: 0 },
          header: { display: 'none' },
        }}
      >
        {showChat && <TariffClassification />}
      </Drawer>

      {/* {location.pathname !== `/${customerId}/${projectId}` && ( */}
      <Button
        onClick={() => setShowChat(true)}
        type="primary"
        style={{ position: 'fixed', bottom: -7, right: 120, zIndex: 5 }}
      >
        <WechatFilled /> {t('Tariff Classification')}
      </Button>
      {/* )} */}
    </>
  );
}
