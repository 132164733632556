import {
  Button,
  Col,
  DatePicker,
  Flex,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import {
  AggregatedCaseDataModel,
  BuyerSellerRelationship,
  BuyerSellerRelationshipMode,
  ConstellationOfParticipantsModel,
  DeclarationType,
  LineItemModel,
  Meta,
  Money,
  PersonModel,
  Procedure,
  ProcedureMode,
  RepresentativeConstellation,
  TradePreference,
  TypeOfBusiness,
  TypeOfBusinessType,
  TypeOfExportDeclarationModel,
  XKSpecificLineItem,
} from 'digicust_types';
import { PaymentMethod } from 'digicust_types/lib/models/digicust/customs/payment-method.model';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDataContext } from '../../Pages/AppDataProvider';
import XKProcedureSelect from '../Country-Specific/XK/ProcedureSelect';
import { RootState } from '../reduxToolkit/store';
import AmountOfMoneyInput from './AmountOfMoneyInput';
import BuyerSellerRelationSelect from './BuyerSellerRelationSelect';
import DebouncedInput from './Common/DebouncedInput';
import ConstellationOfParticipantsSelect from './ConstellationOfParticipantsSelect';
import DeclarationTypeSelect from './DeclarationTypeSelect';
import PaymentMethodSelect from './PaymentMethodSelect';
import ProcedureSelect from './ProcedureSelect';
import TradePreferenceInput from './TradePreferenceInput';
import TypeOfBusinessSelect from './TypeOfBusinessSelect';
import TypeOfExportDeclarationSelect from './TypeOfExportDeclarationSelect';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

type SuggestionValue = {
  input?: string;
  value?: string;
  valid?: boolean;
  requestedProcedure?: string;
  previousProcedure?: string;
  procedureCodeCombination?: string;
  DE_A0121?: string;
  label?: string;
  DE_A0127?: string;
  DE_A1770?: string;
  DE_A1760?: string;
  NC_25000?: string;
  DE_A1150?: string;
  C0116?: string;
};

type FieldType = {
  title: string;
  getValue: () => any;
  renderComponent: (
    value: any,
    updateProperty: (data: any) => void,
  ) => ReactNode;
  updateProperty: (data: any) => void;
  help?: ReactNode;
  suggestions?: { title: string; value: SuggestionValue }[];
};

const GeneralDetailsInput = ({
  value,
  onChange,
  onLineItemsChange,
}: {
  value?: AggregatedCaseDataModel;
  onChange: (value: AggregatedCaseDataModel) => void;
  onLineItemsChange: (value: LineItemModel[]) => void;
}) => {
  const { t } = useTranslation();
  const updateItem = (item: AggregatedCaseDataModel) => {
    onChange(item);
  };

  const storData = useSelector((state: RootState) => state?.caseSlices);
  const errors = storData?.errors;
  const { projectDetails } = useAppDataContext();

  const fields: FieldType[] = [
    projectDetails?.tariffNumberTreeSystem === 'XK'
      ? {
          title: `${t('Procedure')} XK`,
          getValue: () => value?.countrySpecific?.XK as XKSpecificLineItem,
          updateProperty: (data: XKSpecificLineItem) => {
            updateItem({
              ...(value || {}),
              countrySpecific: {
                ...(value?.countrySpecific || {}),
                XK: {
                  ...(value?.countrySpecific?.XK || {}),
                  ...data,
                },
              },
            });
            Modal.confirm({
              title: 'Do you want to apply this procedure to all line items?',

              content: 'This changes the procedure on all line items',
              onOk() {
                console.log('OK');
                if (value) {
                  onLineItemsChange(
                    value?.items?.map?.((item) => {
                      item.countrySpecific = {
                        ...(item?.countrySpecific || {}),
                        XK: {
                          ...(item?.countrySpecific?.XK || {}),
                          ...data,
                        },
                      };
                      return item;
                    }) || [],
                  );
                }
              },

              onCancel() {
                console.log('Cancel');
              },
            });
          },
          renderComponent: (
            value: XKSpecificLineItem,
            updateProperty: (data?: XKSpecificLineItem) => void,
          ) => (
            <XKProcedureSelect
              value={value}
              onChange={(procedure) => {
                updateProperty(procedure);
              }}
            />
          ),
        }
      : {
          title: t('Procedure'),
          getValue: () => value?.procedure,
          updateProperty: (data: Procedure) => {
            updateItem({
              ...(value || {}),
              procedure: data.input || data.value ? data : undefined,
            });
          },
          renderComponent: (
            value: Procedure,
            updateProperty: (data?: Procedure) => void,
          ) => (
            <ProcedureSelect
              value={value}
              onChange={(procedure) => {
                updateProperty(procedure);
              }}
            />
          ),
          suggestions: [
            {
              title: t('Import'),
              value: {
                input: '4000',
                requestedProcedure: '40',
                previousProcedure: '00',
                value: ProcedureMode.import,
                procedureCodeCombination: '4000',
              },
            },
            {
              title: t('Export'),
              value: {
                input: '1000',
                requestedProcedure: '10',
                previousProcedure: '00',
                value: ProcedureMode.export,
                procedureCodeCombination: '1000',
              },
            },
          ],
        },
    {
      title: t('Declaration Type'),
      getValue: () => value?.declarationType,
      updateProperty: (data: Meta<DeclarationType>) => {
        updateItem({ ...(value || {}), declarationType: data });
      },
      renderComponent: (
        value: Meta<DeclarationType>,
        updateProperty: (data?: Meta<DeclarationType>) => void,
      ) => (
        <DeclarationTypeSelect
          value={value}
          onChange={(declarationType) => {
            updateProperty(declarationType);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Pre-Declaration'),
          value: {
            value: DeclarationType.preDeclaration,
            input: DeclarationType.preDeclaration,
          },
        },
        {
          title: t('Declaration'),
          value: {
            value: DeclarationType.declaration,
            input: DeclarationType.declaration,
          },
        },
      ],
    },
    !(projectDetails?.tariffNumberTreeSystem !== 'DE')
      ? {
          title: `${t('Declaration Type')} DE`,
          getValue: () => value?.countrySpecific?.DE?.declarationType,
          updateProperty: (data: Meta<string>) => {
            updateItem({
              ...(value || {}),
              countrySpecific: {
                ...(value?.countrySpecific || {}),
                DE: {
                  ...(value?.countrySpecific?.DE || {}),
                  declarationType: data,
                },
              },
            });
          },
          renderComponent: (
            value: Meta<string>,
            updateProperty: (data?: Meta<string>) => void,
          ) => (
            <DebouncedInput
              placeholder={t('EX, CO, T1, T2, ...')}
              value={value?.value}
              onChange={(val) => {
                updateProperty({ value: val });
              }}
            />
          ),
        }
      : null,
    !(projectDetails?.tariffNumberTreeSystem !== 'DE')
      ? {
          title: `${t('Authorisation Number')} Export DE`,
          getValue: () => value?.countrySpecific?.DE?.export?.authorization,
          updateProperty: (
            data: {
              type?: Meta<string>;
              authorizationNumber?: Meta<string>;
            }[],
          ) => {
            updateItem({
              ...(value || {}),
              countrySpecific: {
                ...(value?.countrySpecific || {}),
                DE: {
                  ...(value?.countrySpecific?.DE || {}),
                  export: {
                    ...(value?.countrySpecific?.DE?.export || {}),
                    authorization: data,
                  },
                },
              },
            });
          },
          renderComponent: (
            value: {
              type?: Meta<string>;
              authorizationNumber?: Meta<string>;
            }[],
            updateProperty: (
              data?: {
                type?: Meta<string>;
                authorizationNumber?: Meta<string>;
              }[],
            ) => void,
          ) => {
            // Handler to add a new authorization entry
            const handleAddAuthorization = () => {
              const newAuthorization = {
                type: { value: '' },
                authorizationNumber: { value: '' },
              };
              const updatedAuthorizations = [
                ...(value || []),
                newAuthorization,
              ];
              updateProperty(updatedAuthorizations);
            };

            // Handler to remove an authorization entry
            const handleRemoveAuthorization = (index: number) => {
              const updatedAuthorizations = value.filter((_, i) => i !== index);
              updateProperty(updatedAuthorizations);
            };

            // Handler to update the type of an authorization
            const handleTypeChange = (index: number, newType: string) => {
              const updatedAuthorizations = value.map((e, i) =>
                i === index
                  ? { ...e, type: { value: newType, input: newType } }
                  : e,
              );
              updateProperty(updatedAuthorizations);
            };

            // Handler to update the authorization number
            const handleNumberChange = (index: number, newNumber: string) => {
              const updatedAuthorizations = value.map((e, i) =>
                i === index
                  ? {
                      ...e,
                      authorizationNumber: {
                        value: newNumber,
                        input: newNumber,
                      },
                    }
                  : e,
              );
              updateProperty(updatedAuthorizations);
            };

            return (
              <div>
                {value?.length ? (
                  value?.map?.((auth, index) => (
                    <Row wrap={false}>
                      <Col flex="none">
                        <Select
                          variant="filled"
                          value={auth?.type?.value || undefined}
                          onChange={(value) => handleTypeChange(index, value)}
                          placeholder={t('Select Type')}
                          style={{ width: 100 }}
                          popupMatchSelectWidth={false}
                          showSearch
                          optionFilterProp="children"
                        >
                          {[
                            { code: 'C019', description: 'Passive Veredelung' },
                            {
                              code: 'C512',
                              description: 'Vereinfachtes Verfahren',
                            },
                            {
                              code: 'C513',
                              description: 'Zentrale Zollabwicklung',
                            },
                            {
                              code: 'C514',
                              description:
                                'Anschreibung in der Buchführung des Anmelders',
                            },
                            { code: 'C517', description: 'Privates Zolllager' },
                            {
                              code: 'C518',
                              description: 'Öffentliches Zolllager (Typ I)',
                            },
                            {
                              code: 'C519',
                              description: 'Öffentliches Zolllager (Typ II)',
                            },
                          ].map((item) => (
                            <Select.Option key={item.code} value={item.code}>
                              {item.code} - {item.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                      <Col flex="auto">
                        <DebouncedInput
                          placeholder={t('Authorization Number')}
                          value={auth?.authorizationNumber?.value}
                          onChange={(e) => handleNumberChange(index, e || '')}
                        />
                      </Col>
                      <Col flex="none">
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => handleRemoveAuthorization(index)}
                        />
                      </Col>
                      {index === (value?.length ? value?.length - 1 : 0) && (
                        <Col flex="none">
                          <Button
                            type="text"
                            onClick={handleAddAuthorization}
                            icon={<PlusOutlined />}
                            title={t('Add Authorization')}
                          ></Button>
                        </Col>
                      )}
                    </Row>
                  ))
                ) : (
                  <Button type="link" onClick={handleAddAuthorization}>
                    {t('Add Authorization')}
                  </Button>
                )}
              </div>
            );
          },
        }
      : null,
    !(projectDetails?.tariffNumberTreeSystem !== 'DE')
      ? {
          title: `${t('ABD Email Address')} DE`,
          getValue: () => value?.countrySpecific?.DE?.ABDEmailAddress,
          updateProperty: (data: Meta<string>) => {
            updateItem({
              ...(value || {}),
              countrySpecific: {
                ...(value?.countrySpecific || {}),
                DE: {
                  ...(value?.countrySpecific?.DE || {}),
                  ABDEmailAddress: data,
                },
              },
            });
          },
          suggestions: [
            {
              title: t('Whoever uploads'),
              value: {
                value:
                  '{{$contains(createdBy.displayName,"@")?createdBy.displayName:documents[documentType="email"].from}}',
                input:
                  '{{$contains(createdBy.displayName,"@")?createdBy.displayName:documents[documentType="email"].from}}',
              },
            },
          ],
          renderComponent: (
            value: Meta<string>,
            updateProperty: (data?: Meta<string>) => void,
          ) => (
            <DebouncedInput
              placeholder={t('max@mustermann.de')}
              value={value?.value}
              onChange={(val) => {
                updateProperty({ value: val });
              }}
            />
          ),
        }
      : null,
    !(projectDetails?.tariffNumberTreeSystem !== 'DE')
      ? {
          title: `${t('AVM Email Address')} DE`,
          getValue: () => value?.countrySpecific?.DE?.AVMEmailAddress,
          updateProperty: (data: Meta<string>) => {
            updateItem({
              ...(value || {}),
              countrySpecific: {
                ...(value?.countrySpecific || {}),
                DE: {
                  ...(value?.countrySpecific?.DE || {}),
                  AVMEmailAddress: data,
                },
              },
            });
          },
          suggestions: [
            {
              title: t('Whoever uploads'),
              value: {
                value:
                  '{{$contains(createdBy.displayName,"@")?createdBy.displayName:documents[documentType="email"].from}}',
                input:
                  '{{$contains(createdBy.displayName,"@")?createdBy.displayName:documents[documentType="email"].from}}',
              },
            },
          ],
          renderComponent: (
            value: Meta<string>,
            updateProperty: (data?: Meta<string>) => void,
          ) => (
            <DebouncedInput
              placeholder={t('max@mustermann.de')}
              value={value?.value}
              onChange={(val) => {
                updateProperty({ value: val });
              }}
            />
          ),
        }
      : null,
    !(projectDetails?.tariffNumberTreeSystem !== 'DE')
      ? {
          title: `${t('Transit Declaration Type')} DE`,
          getValue: () => value?.countrySpecific?.DE?.transitDeclarationType,
          updateProperty: (data: Meta<string>) => {
            updateItem({
              ...(value || {}),
              countrySpecific: {
                ...(value?.countrySpecific || {}),
                DE: {
                  ...(value?.countrySpecific?.DE || {}),
                  transitDeclarationType: data,
                },
              },
            });
          },
          renderComponent: (
            value: Meta<string>,
            updateProperty: (data?: Meta<string>) => void,
          ) => (
            <DebouncedInput
              placeholder={t('e.g. 11, 10')}
              value={value?.value}
              onChange={(val) => {
                updateProperty({ value: val });
              }}
            />
          ),
        }
      : null,
    {
      title: t('Responsible Person'),
      getValue: () => value?.responsiblePerson,
      updateProperty: (data: PersonModel) => {
        updateItem({ ...(value || {}), responsiblePerson: data });
      },
      renderComponent: (
        value: PersonModel,
        updateProperty: (data?: PersonModel) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Responsible Person')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: t('Export Declaration Type (Export only)'),
      getValue: () => value?.exportDeclarationType,
      updateProperty: (data: TypeOfExportDeclarationModel) => {
        updateItem({ ...(value || {}), exportDeclarationType: data });
      },
      renderComponent: (
        value: TypeOfExportDeclarationModel,
        updateProperty: (data?: TypeOfExportDeclarationModel) => void,
      ) => (
        <TypeOfExportDeclarationSelect
          value={value}
          onChange={(exportDeclarationType) => {
            updateProperty(exportDeclarationType);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Default Declaration'),
          value: {
            input: '00000100',
            value: '00000100',
            DE_A0121: '00000100',
            label: t(
              'Standard export declaration for the two-step normal procedure.',
            ),
          },
        },
      ],
    },
    {
      title: t('Representation'),
      getValue: () => value?.constellationOfParticipants,
      updateProperty: (data: ConstellationOfParticipantsModel) => {
        updateItem({ ...(value || {}), constellationOfParticipants: data });
      },
      renderComponent: (
        value: ConstellationOfParticipantsModel,
        updateProperty: (data?: ConstellationOfParticipantsModel) => void,
      ) => (
        <ConstellationOfParticipantsSelect
          value={value}
          onChange={(constellationOfParticipants) => {
            updateProperty(constellationOfParticipants);
          }}
        />
      ),
      suggestions: [
        {
          title: t('None'),
          value: {
            DE_A0127: '0000',
            DE_A1770: '0',
            input: RepresentativeConstellation.None,
            value: RepresentativeConstellation.None,
          },
        },
        {
          title: t('Direct'),
          value: {
            DE_A0127: '0010',
            DE_A1770: '1',
            input: RepresentativeConstellation.Direct,
            value: RepresentativeConstellation.Direct,
          },
        },
        {
          title: t('Indirect'),
          value: {
            DE_A0127: '1000',
            DE_A1770: '2',
            input: RepresentativeConstellation.Indirect,
            value: RepresentativeConstellation.Indirect,
          },
        },
      ],
    },
    {
      title: t('Buyer-Seller Relationship'),
      getValue: () => value?.buyerSellerRelation,
      updateProperty: (data: BuyerSellerRelationship) => {
        updateItem({ ...(value || {}), buyerSellerRelation: data });
      },
      renderComponent: (
        value: BuyerSellerRelationship,
        updateProperty: (data?: BuyerSellerRelationship) => void,
      ) => (
        <BuyerSellerRelationSelect
          value={value}
          onChange={(buyerSellerRelation) => {
            updateProperty(buyerSellerRelation);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Not connected'),
          value: {
            DE_A1760: '0',
            value: BuyerSellerRelationshipMode.NotConnected,
            input: BuyerSellerRelationshipMode.NotConnected,
          },
        },
      ],
    },
    {
      title: t('Type of Business'),
      getValue: () => value?.typeofBusiness,
      updateProperty: (data: TypeOfBusiness) => {
        updateItem({ ...(value || {}), typeofBusiness: data });
      },
      renderComponent: (
        value: TypeOfBusiness,
        updateProperty: (data?: TypeOfBusiness) => void,
      ) => (
        <TypeOfBusinessSelect
          value={value}
          onChange={(typeofBusiness) => {
            updateProperty(typeofBusiness);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Purchase'),
          value: {
            value: TypeOfBusinessType.Purchase,
            input: TypeOfBusinessType.Purchase,
            NC_25000: '11',
            DE_A1150: '11',
          },
        },
      ],
    },
    {
      title: t('Trade Preference'),
      getValue: () => value?.tradePreference,
      updateProperty: (data: TradePreference) => {
        updateItem({ ...(value || {}), tradePreference: data });
      },
      renderComponent: (
        value: TradePreference,
        updateProperty: (data?: TradePreference) => void,
      ) => (
        <TradePreferenceInput
          preference={value}
          onChange={(tradePreference) => {
            updateProperty(tradePreference);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Valid'),
          value: {
            valid: true,
          },
        },
        {
          title: t('Invalid'),
          value: {
            valid: false,
          },
        },
      ],
    },
    {
      title: t('Additional Information'),
      getValue: () => value?.additionalInformation,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(value || {}), additionalInformation: data });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Additional Information')}
          value={value?.value}
          onChange={(person) => {
            updateProperty({ value: person });
          }}
        />
      ),
    },
    {
      title: t('Import Sales Tax'),
      getValue: () => value?.importSalesTax,
      updateProperty: (data: Money) => {
        updateItem({ ...(value || {}), importSalesTax: data });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'importSalesTax'}
          warningValue={errors?.actual}
          value={value}
          onChange={(importSalesTax) => {
            updateProperty(importSalesTax);
          }}
        />
      ),
    },
    {
      title: t('Total Value'),
      getValue: () => value?.totalValue,
      updateProperty: (data: Money) => {
        updateItem({ ...(value || {}), totalValue: data });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'totalValue'}
          warningValue={errors?.actual}
          value={value}
          onChange={(totalValue) => {
            updateProperty(totalValue);
          }}
        />
      ),
    },
    {
      title: t('Statistical Value'),
      getValue: () => value?.statisticalValue,
      updateProperty: (data: Money) => {
        updateItem({ ...(value || {}), statisticalValue: data });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'statisticalValue'}
          warningValue={errors?.actual}
          value={value}
          onChange={(statisticalValue) => {
            updateProperty(statisticalValue);
          }}
        />
      ),
    },
    {
      title: t('Guarantee Value'),
      getValue: () => value?.guaranteeValue,
      updateProperty: (data: Money) => {
        updateItem({ ...(value || {}), guaranteeValue: data });
      },
      renderComponent: (
        value: Money,
        updateProperty: (data?: Money) => void,
      ) => (
        <AmountOfMoneyInput
          keyValue={'guaranteeValue'}
          warningValue={errors?.actual}
          value={value}
          onChange={(guaranteeValue) => {
            updateProperty(guaranteeValue);
          }}
        />
      ),
    },
    {
      title: t('Payment Method'),
      getValue: () => value?.paymentMethod,
      updateProperty: (data: PaymentMethod) => {
        updateItem({ ...(value || {}), paymentMethod: data });
      },
      renderComponent: (
        value: PaymentMethod,
        updateProperty: (data?: PaymentMethod) => void,
      ) => (
        <PaymentMethodSelect
          value={value}
          onChange={(paymentMethod) => {
            updateProperty(paymentMethod);
          }}
        />
      ),
      suggestions: [
        {
          title: t('Electronic payment'),
          value: {
            value: 'H',
            label: 'Electronic payment',
            C0116: 'H',
          },
        },
        {
          title: t('Cash'),
          value: {
            value: 'A',
            label: 'Cash payment',
            C0116: 'A',
          },
        },
      ],
    },
    {
      title: 'GRN',
      getValue: () => value?.GRN,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(value || {}), GRN: data });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder="GRN"
          value={value?.value}
          onChange={(e) => {
            updateProperty({ value: e });
          }}
        />
      ),
    },
    {
      title: t('GRN Access Code'),
      getValue: () => value?.GRNAccessCode,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(value || {}), GRNAccessCode: data });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('GRN Access Code')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: 'LRN',
      getValue: () => value?.LRN,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(value || {}), LRN: data });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder="LRN"
          value={value?.value}
          onChange={(LRN) => {
            updateProperty({ value: LRN });
          }}
        />
      ),
    },
    {
      title: t('Reference number UCR'),
      getValue: () => value?.referenceNumberUCR,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(value || {}), referenceNumberUCR: data });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Reference number UCR')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: t('Registration number external'),
      getValue: () => value?.registrationNumberExternal,
      updateProperty: (data: Meta<string>) => {
        updateItem({ ...(value || {}), registrationNumberExternal: data });
      },
      renderComponent: (
        value: Meta<string>,
        updateProperty: (data?: Meta<string>) => void,
      ) => (
        <DebouncedInput
          placeholder={t('Registration number external')}
          value={value?.value}
          onChange={(val) => {
            updateProperty({ value: val });
          }}
        />
      ),
    },
    {
      title: t('Presentation Date'),
      getValue: () => ({
        presentationDate: value?.presentationDate,
        presentationTimeStart: value?.presentationTimeStart,
        presentationTimeEnd: value?.presentationTimeEnd,
      }),
      updateProperty: (data: {
        presentationDate?: Meta<string>;
        presentationTimeStart?: Meta<string>;
        presentationTimeEnd?: Meta<string>;
      }) => {
        updateItem({
          ...(value || {}),
          presentationDate: data?.presentationDate,
          presentationTimeStart: data?.presentationTimeStart,
          presentationTimeEnd: data?.presentationTimeEnd,
        });
      },
      renderComponent: (
        value: {
          presentationDate?: Meta<string>;
          presentationTimeStart?: Meta<string>;
          presentationTimeEnd?: Meta<string>;
        },
        updateProperty: (data?: {
          presentationDate?: Meta<string>;
          presentationTimeStart?: Meta<string>;
          presentationTimeEnd?: Meta<string>;
        }) => void,
      ) => (
        <>
          <Space.Compact style={{ width: '100%' }}>
            <DatePicker
              variant="filled"
              value={
                value?.presentationDate?.value
                  ? dayjs(value?.presentationDate?.value)
                  : null
              }
              onChange={(val) => {
                updateProperty({
                  ...(value || {}),
                  presentationDate: {
                    value: val?.format('YYYY-MM-DD'),
                    input: val?.format('YYYY-MM-DD'),
                  },
                });
              }}
            />

            <TimePicker.RangePicker
              variant="filled"
              value={[
                value?.presentationTimeStart?.value
                  ? dayjs(value?.presentationTimeStart?.value, 'HH:mm:ss')
                  : null,
                value?.presentationTimeEnd?.value
                  ? dayjs(value?.presentationTimeEnd?.value, 'HH:mm:ss')
                  : null,
              ]}
              onChange={(val) => {
                updateProperty({
                  ...(value || {}),
                  presentationTimeStart: {
                    input: val?.[0]?.format?.('HH:mm:ss'),
                    value: val?.[0]?.format?.('HH:mm:ss'),
                  },
                  presentationTimeEnd: {
                    input: val?.[1]?.format?.('HH:mm:ss'),
                    value: val?.[1]?.format?.('HH:mm:ss'),
                  },
                });
              }}
            />
          </Space.Compact>
        </>
      ),
    },
  ]?.filter((e) => e !== null) as FieldType[];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {fields.map((field) => (
        <Flex justify="space-between" align="center" gap={5}>
          <Typography.Paragraph>
            <Popover placement="right" content={field.help}>
              {field.title}
            </Popover>
          </Typography.Paragraph>

          <div style={{ flex: 1, textAlign: 'end' }}>
            {!field.getValue() && (
              <Space.Compact direction="horizontal" style={{ height: '100%' }}>
                {field.suggestions?.map((suggestion) => (
                  <Button
                    type="dashed"
                    size="small"
                    tabIndex={-1}
                    onClick={() => {
                      field.updateProperty(suggestion?.value);
                    }}
                  >
                    {suggestion?.title}
                  </Button>
                ))}
              </Space.Compact>
            )}
          </div>
          <div style={{ width: '40%' }}>
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        </Flex>
      ))}
    </Space>
  );
};

export default GeneralDetailsInput;
