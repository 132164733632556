import { Input, Tag } from 'antd';
import { TradePreference } from 'digicust_types';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

const TradePreferenceInput = ({
  preference,
  onChange,
}: {
  preference?: TradePreference;
  onChange?: (value: TradePreference) => void;
}) => {
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (preference?.value) setValue(preference?.value);
  }, [preference?.value]);

  const { t } = useTranslation();

  return (
    <Input
      variant="filled"
      prefix={
        <>
          {preference?.valid ? (
            <Tag color="success">{t('Valid')}</Tag>
          ) : preference?.valid === false ? (
            <Tag color="warning">{t('Invalid')}</Tag>
          ) : null}

          {preference?.country ? (
            <ReactCountryFlag svg countryCode={preference?.country} />
          ) : null}

          {preference?.country && preference?.treaty ? (
            <Tag>{`${preference?.treaty}`}</Tag>
          ) : null}
        </>
      }
      placeholder={t('Trade Preference')}
      value={value}
      title={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() =>
        onChange && onChange({ ...preference, input: value, value: value })
      }
    />
  );
};

export default TradePreferenceInput;
