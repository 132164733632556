import { Checkbox, Form, Input } from 'antd';
import { ScopeSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function ScopeSystemForm({
  config,
  changeData,
}: {
  config: ScopeSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('DNS Name')}>
        <Input
          variant="filled"
          placeholder={t('DNS Name')}
          value={config?.['sftp-dns-name']}
          onChange={(e) => changeData('sftp-dns-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Port')}>
        <Input
          variant="filled"
          placeholder={t('Port')}
          value={config?.['sftp-port']}
          onChange={(e) => changeData('sftp-port', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Login name')}>
        <Input
          variant="filled"
          placeholder={t('Login name')}
          value={config?.['sftp-login-name']}
          onChange={(e) => changeData('sftp-login-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          variant="filled"
          placeholder={t('Password')}
          value={config?.['sftp-password']}
          onChange={(e) => changeData('sftp-password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Inbound')}>
        <Input
          variant="filled"
          placeholder={t('Inbound')}
          value={config?.['sftp-inbound']}
          onChange={(e) => changeData('sftp-inbound', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Organization')}>
        <Input
          variant="filled"
          placeholder={t('Organization')}
          value={config?.organization}
          onChange={(e) => changeData('organization', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Partner Code')}>
        <Input
          variant="filled"
          placeholder={t('Partner Code')}
          value={config?.partnerCode}
          onChange={(e) => changeData('partnerCode', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Branch')}>
        <Input
          variant="filled"
          placeholder={t('Branch')}
          value={config?.branch}
          onChange={(e) => changeData('branch', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Configuration')}>
        <Input
          variant="filled"
          placeholder={t('Configuration')}
          value={config?.configuration}
          onChange={(e) => changeData('configuration', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('User ID')}>
        <Input
          variant="filled"
          placeholder={t('User ID')}
          value={config?.userId}
          onChange={(e) => changeData('userId', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Template')}>
        <Input
          variant="filled"
          placeholder={t('Template')}
          value={config?.template}
          onChange={(e) => changeData('template', e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={config?.sendAutomatic as any as boolean}
          onChange={(e) => changeData('sendAutomatic', e.target.checked)}
        >
          {t('Send Automatic')}
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={config?.accumulatePosition as any as boolean}
          onChange={(e) => changeData('accumulatePosition', e.target.checked)}
        >
          {t('Accumulate Position')}
        </Checkbox>
      </Form.Item>
    </Form>
  );
}
