import { useDebounce } from '@uidotdev/usehooks';
import { Button, Select, Space, Typography } from 'antd';
import { FilterCondition, StakeholderModel } from 'digicust_types';
import { useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StakeholderPage from '../../Pages/MasterData/Stakeholders/StakeholderPage';
import getUniqueObjects from '../../utils/helpers/getUniqueObjects';
import { StakeholderRepository } from '../../utils/repositories/stakeholder.repository';
import { updateStakeholderData } from '../reduxToolkit/masterStakeholderSlice';
import { RootState } from '../reduxToolkit/store';

type StakeholderSelectProps = {
  value?: StakeholderModel;
  onChange?: (value: StakeholderModel) => void;
  status?: boolean;
  donotShowAddButton?: boolean;
  stakeholderType?: string;
};

const StakeholderSelect = ({
  value,
  onChange,
  status,
  donotShowAddButton = false,
  stakeholderType,
}: StakeholderSelectProps) => {
  const { customerId, projectId } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StakeholderModel[]>([]);

  const [stakeholderStatus, setStakeholderStatus] = useState<any>(
    !status && !value?.id ? 'warning' : '',
  );
  const [search, setSearch] = useState('');
  const searchDebounce = useDebounce(search, 800);

  const stakeholderData = useSelector(
    (state: RootState) => state.stakeholder.data,
  );

  const [openStakeholder, setOpenStakeholder] = useState(false);
  const dispatch = useDispatch();

  const fetch = async (id?: string, searchVal?: string) => {
    setLoading(true);
    const response = await StakeholderRepository.getList(
      customerId,
      projectId,
      {
        filter: [
          ...(id
            ? [
                {
                  field: 'id',
                  condition: FilterCondition.contains,
                  value: id,
                },
              ]
            : []),
          ...(searchVal
            ? [
                {
                  field: 'name.value',
                  condition: FilterCondition.contains,
                  value: searchVal,
                },
              ]
            : []),
          ...(stakeholderType
            ? [
                {
                  field: 'roles',
                  condition: FilterCondition.contains,
                  value: stakeholderType.toLowerCase(),
                },
              ]
            : []),
        ],
        sort: [{ field: '_ts', order: 'DESC' }],
        pageLimit: 10,
      },
    );

    const newRes = getUniqueObjects(
      [
        ...(response?.result || []),
        ...(searchVal ? [] : data || []),
        value || {}, // To make sure selected value is always present.
      ],
      'id',
    );

    setData(newRes);
    if (!searchVal) {
      dispatch(updateStakeholderData(newRes));
    }

    setLoading(false);
  };

  useMemo(() => {
    if (!value?.id) return;

    const newData = getUniqueObjects([...data, value], 'id');
    if (newData.length > 0 && !newData.find((item) => item.id === value.id)) {
      fetch(value.id);
    }
    setData(newData);
    setStakeholderStatus('');
  }, [value?.id]);

  useEffect(() => {
    if (searchDebounce !== '') fetch('', searchDebounce);
  }, [searchDebounce]);

  useEffect(() => {
    if (stakeholderType) fetch();
  }, [stakeholderType]);

  useEffect(() => {
    if (stakeholderData?.length > 9) {
      setData(stakeholderData);
    } else {
      fetch();
    }
  }, []);

  const filterStakeholders = (inputValue: any, option: any) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const clickHandler = () => {
    setOpenStakeholder(true);
  };

  const handleSelectChange = (value: string) => {
    if (value === 'add_stakeholder') {
      clickHandler();
    } else {
      if (onChange) {
        const selectedItem = data.find((item) => item.id === value);
        onChange(selectedItem || {});
      }
    }
  };

  return (
    <>
      <StakeholderPage
        selectedRows={['new']}
        open={openStakeholder}
        setClose={() => setOpenStakeholder(false)}
      />

      <Select
        variant="filled"
        style={{ width: '100%' }}
        loading={loading}
        placeholder={t('Stakeholder')}
        status={stakeholderStatus}
        value={value?.id}
        onChange={handleSelectChange}
        showSearch
        allowClear
        onSearch={(val: string) => setSearch(val)}
        filterOption={filterStakeholders}
      >
        {!donotShowAddButton && (
          <Select.Option value="add_stakeholder" label="Add Stakeholder">
            <Button onClick={clickHandler} block>
              + {t('Add Stakeholder')}
            </Button>
          </Select.Option>
        )}

        {data
          ?.filter((item) => item?.name?.value)
          ?.map((item, key) => (
            <Select.Option
              title={`${item?.name?.value}${
                item?.address?.formattedAddress
                  ? `\n${item?.address?.formattedAddress}`
                  : ''
              }${item?.EORI?.value ? `\nEORI: ${item?.EORI?.value}` : ''}${
                item?.VAT?.value ? `\nVAT: ${item?.VAT?.value}` : ''
              }`}
              key={key}
              value={item?.id}
              label={item?.name?.value}
            >
              <Space>
                <Typography.Text>
                  <ReactCountryFlag
                    svg
                    style={{ marginRight: '5px' }}
                    countryCode={item?.address?.alpha2Code as any}
                  />{' '}
                  {item?.name?.value}
                </Typography.Text>
                <Typography.Text
                  style={{ maxWidth: '250px' }}
                  ellipsis
                  type="secondary"
                >
                  {item?.address?.formattedAddress}
                </Typography.Text>
              </Space>
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export default StakeholderSelect;
