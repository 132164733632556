import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Select,
  Space,
  Switch,
} from 'antd';
import { EmailEvent, WebhookEvent, SFTPEvent } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import MappingInput from '../../../../Components/Inputs/MappingInput';
import CardWrapper from '../../Common/CardWrapper';

type Event = EmailEvent | WebhookEvent | SFTPEvent;

const EventComponent = ({
  value,
  onChange,
  onDelete,
  onCopy,
}: {
  value: Event;
  onChange: (value: Event) => void;
  onDelete: () => void;
  onCopy: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Collapse>
      <Collapse.Panel
        key="1"
        header={`When ${value.checkpoint} then send ${value.type}`}
        style={{ width: '100%' }}
        extra={
          <Space size="small">
            <Button size="small" type="link" onClick={onCopy}>
              <CopyOutlined />
            </Button>
            <Button size="small" type="link" onClick={onDelete}>
              <DeleteOutlined />
            </Button>
          </Space>
        }
      >
        <Space
          direction="vertical"
          style={{ width: '100%', maxWidth: '900px', padding: 10 }}
        >
          <Space style={{ paddingLeft: '10px' }}>
            <Select
              style={{ width: '180px' }}
              placeholder="Checkpoint"
              optionFilterProp="children"
              value={value.checkpoint}
              onChange={(e) => onChange({ ...value, checkpoint: e })}
            >
              <Select.Option value="upload">{t('After upload')}</Select.Option>
              <Select.Option value="processed">
                {t('After case processing')}
              </Select.Option>
            </Select>
            <Select
              style={{ width: '180px' }}
              placeholder="Type"
              optionFilterProp="children"
              value={value.type}
              onChange={(e) => onChange({ ...value, type: e })}
            >
              <Select.Option value="email">{t('Send email')}</Select.Option>
              <Select.Option value="sftp">{t('Send to SFTP')}</Select.Option>
              <Select.Option value="webhook">
                {t('Trigger a webhook')}
              </Select.Option>
            </Select>

            <Checkbox
              checked={value?.suppressMultipleExecutions}
              onChange={(e) => {
                onChange({
                  ...value,
                  suppressMultipleExecutions: e.target.checked,
                });
              }}
            >
              {t('Suppress multiple executions')}
            </Checkbox>
          </Space>

          {value.type === 'email' && (
            <CardWrapper title={t('Config Email Event')}>
              <Form size="small">
                <Form.Item label={t('Recipients')}>
                  <Select
                    mode="tags"
                    style={{ minWidth: '300px' }}
                    placeholder={t('Tags Mode')}
                    value={value.emailAddress?.split(';')}
                    onChange={(e) =>
                      onChange({ ...value, emailAddress: e.join(';') })
                    }
                    options={[
                      {
                        value: 'validation@digicust.com',
                        label: 'Digicust Validation',
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item label={t('Title')}>
                  <Input
                    placeholder={t('Title')}
                    value={value?.title}
                    onChange={(e) =>
                      onChange({ ...value, title: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item label={t('Title Mapping')}>
                  <MappingInput
                    placeholder={t('Title Mapping')}
                    value={value?.titleMapping}
                    onChange={(e) =>
                      onChange({ ...value, titleMapping: e || undefined })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Body')}>
                  <Input
                    placeholder={t('Body')}
                    value={value?.body}
                    onChange={(e) =>
                      onChange({ ...value, body: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Body Mapping')}>
                  <MappingInput
                    placeholder={t('Body Mapping')}
                    value={value?.bodyMapping}
                    onChange={(e) =>
                      onChange({ ...value, bodyMapping: e || undefined })
                    }
                  />
                </Form.Item>

                <Form.Item label={t('Attach documents')}>
                  <Switch
                    checked={value.attachDocuments?.active}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        attachDocuments: {
                          ...(value.attachDocuments || {}),
                          active: e,
                        },
                      })
                    }
                  />
                </Form.Item>

                {value.attachDocuments?.active && (
                  <Form.Item
                    label={t('File name mapping')}
                    style={{ marginLeft: '30px' }}
                  >
                    <MappingInput
                      placeholder={t('File name mapping')}
                      value={value?.attachDocuments?.fileNameMapping}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          attachDocuments: {
                            ...(value?.attachDocuments || {}),
                            fileNameMapping: e || undefined,
                          },
                        })
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item label={t('Attach case information')}>
                  <Switch
                    checked={value.attachCase?.active}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        attachCase: {
                          ...(value.attachCase || {}),
                          active: e,
                        },
                      })
                    }
                  />
                </Form.Item>

                {value.attachCase?.active && (
                  <CardWrapper>
                    <Form.Item label={t('Format')}>
                      <Select
                        placeholder={t('Format')}
                        optionFilterProp="children"
                        value={value?.attachCase?.format}
                        onChange={(e) =>
                          onChange({
                            ...value,
                            attachCase: {
                              ...(value?.attachCase || {}),
                              format: e,
                            },
                          })
                        }
                      >
                        <Select.Option value="JSON">JSON</Select.Option>
                        <Select.Option value="XML">XML</Select.Option>
                        <Select.Option value="CSV">CSV</Select.Option>
                        <Select.Option value="TXT">TXT</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label={t('File name mapping')}>
                      <MappingInput
                        placeholder={t('File name mapping')}
                        value={value?.attachCase?.fileNameMapping}
                        onChange={(e) =>
                          onChange({
                            ...value,
                            attachCase: {
                              ...(value?.attachCase || {}),
                              fileNameMapping: e || undefined,
                            },
                          })
                        }
                      />
                    </Form.Item>
                    {value?.attachCase?.format === 'CSV' && (
                      <>
                        <Form.Item label={t('Separator')}>
                          <Input
                            placeholder={t('Separator')}
                            value={value?.attachCase?.csvSeparator}
                            onChange={(e) =>
                              onChange({
                                ...value,
                                attachCase: {
                                  ...(value?.attachCase || {}),
                                  csvSeparator: e.target.value,
                                },
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item label={t('Force Delimiter')}>
                          <Checkbox
                            checked={value?.attachCase?.csvForceTextDelimiter}
                            onChange={(e) =>
                              onChange({
                                ...value,
                                attachCase: {
                                  ...(value?.attachCase || {}),
                                  csvForceTextDelimiter: e.target.checked,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </>
                    )}
                    <Form.Item label={t('Mapping')}>
                      <MappingInput
                        placeholder={t('Mapping')}
                        value={value?.attachCase?.mapping}
                        onChange={(e) =>
                          onChange({
                            ...value,
                            attachCase: {
                              ...(value?.attachCase || {}),
                              mapping: e || undefined,
                            },
                          })
                        }
                      />
                    </Form.Item>

                    <Checkbox
                      checked={value?.attachCase?.removeAccents}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          attachCase: {
                            ...(value?.attachCase || {}),
                            removeAccents: e.target.checked,
                          },
                        })
                      }
                    >
                      {t('Remove accents')}
                    </Checkbox>

                    <Checkbox
                      checked={value?.attachCase?.removeEmptyTags}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          attachCase: {
                            ...(value?.attachCase || {}),
                            removeEmptyTags: e.target.checked,
                          },
                        })
                      }
                    >
                      {t('Remove empty tags')}
                    </Checkbox>
                  </CardWrapper>
                )}
              </Form>
            </CardWrapper>
          )}

          {value.type === 'sftp' && (
            <CardWrapper title={t('Config SFTP Event')}>
              <Form size="small">
                <Form.Item label={t('SFTP DNS Name')}>
                  <Input
                    placeholder={t('SFTP DNS Name')}
                    value={value['sftp-dns-name']}
                    onChange={(e) =>
                      onChange({ ...value, 'sftp-dns-name': e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('SFTP Port')}>
                  <Input
                    placeholder={t('SFTP Port')}
                    value={value['sftp-port']}
                    onChange={(e) =>
                      onChange({ ...value, 'sftp-port': e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('SFTP Login Name')}>
                  <Input
                    placeholder={t('SFTP Login Name')}
                    value={value['sftp-login-name']}
                    onChange={(e) =>
                      onChange({ ...value, 'sftp-login-name': e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('SFTP Password')}>
                  <Input.Password
                    placeholder={t('SFTP Password')}
                    value={value['sftp-password']}
                    onChange={(e) =>
                      onChange({ ...value, 'sftp-password': e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('SFTP Path')}>
                  <Input
                    placeholder={t('SFTP Path')}
                    value={value['sftp-path']}
                    onChange={(e) =>
                      onChange({ ...value, 'sftp-path': e.target.value })
                    }
                  />
                </Form.Item>
                {/* <Form.Item label={t('Case File Mapping')}>
                  <MappingInput
                    placeholder={t('Case File Mapping')}
                    value={value.caseFileMapping?.mapping}
                    onChange={(e) =>
                      onChange({ ...value, caseFileMapping: e || undefined })
                    }
                  />
                </Form.Item> */}
                <CardWrapper>
                  <Form.Item label={t('Format')}>
                    <Select
                      placeholder={t('Format')}
                      optionFilterProp="children"
                      value={value?.caseFileMapping?.format}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          caseFileMapping: {
                            ...(value?.caseFileMapping || {}),
                            format: e,
                          },
                        })
                      }
                    >
                      <Select.Option value="JSON">JSON</Select.Option>
                      <Select.Option value="XML">XML</Select.Option>
                      <Select.Option value="CSV">CSV</Select.Option>
                      <Select.Option value="TXT">TXT</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label={t('File name mapping')}>
                    <MappingInput
                      placeholder={t('File name mapping')}
                      value={value?.caseFileMapping?.fileNameMapping}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          caseFileMapping: {
                            ...(value?.caseFileMapping || {}),
                            fileNameMapping: e || undefined,
                          },
                        })
                      }
                    />
                  </Form.Item>
                  {value?.caseFileMapping?.format === 'CSV' && (
                    <>
                      <Form.Item label={t('Separator')}>
                        <Input
                          placeholder={t('Separator')}
                          value={value?.caseFileMapping?.csvSeparator}
                          onChange={(e) =>
                            onChange({
                              ...value,
                              caseFileMapping: {
                                ...(value?.caseFileMapping || {}),
                                csvSeparator: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item label={t('Include headers')}>
                        <Checkbox
                          checked={value?.caseFileMapping?.includeHeaders}
                          onChange={(e) =>
                            onChange({
                              ...value,
                              caseFileMapping: {
                                ...(value?.caseFileMapping || {}),
                                includeHeaders: e.target.checked,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item label={t('Mapping')}>
                    <MappingInput
                      placeholder={t('Mapping')}
                      value={value?.caseFileMapping?.mapping}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          caseFileMapping: {
                            ...(value?.caseFileMapping || {}),
                            mapping: e || undefined,
                          },
                        })
                      }
                    />
                  </Form.Item>

                  <Checkbox
                    checked={value?.caseFileMapping?.removeAccents}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        caseFileMapping: {
                          ...(value?.caseFileMapping || {}),
                          removeAccents: e.target.checked,
                        },
                      })
                    }
                  >
                    {t('Remove accents')}
                  </Checkbox>

                  <Checkbox
                    checked={value?.caseFileMapping?.removeEmptyTags}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        caseFileMapping: {
                          ...(value?.caseFileMapping || {}),
                          removeEmptyTags: e.target.checked,
                        },
                      })
                    }
                  >
                    {t('Remove empty tags')}
                  </Checkbox>
                </CardWrapper>
              </Form>
            </CardWrapper>
          )}

          {value.type === 'webhook' && (
            <CardWrapper title={t('Config Webhook Event')}>
              <Form size="small">
                <Form.Item label={t('Webhook URL')}>
                  <Input
                    placeholder={t('Webhook URL')}
                    value={value.url}
                    onChange={(e) =>
                      onChange({ ...value, url: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Method')}>
                  <Select
                    placeholder={t('Method')}
                    value={value.method}
                    onChange={(e) => onChange({ ...value, method: e })}
                  >
                    <Select.Option value="get">GET</Select.Option>
                    <Select.Option value="post">POST</Select.Option>
                    <Select.Option value="put">PUT</Select.Option>
                    <Select.Option value="patch">PATCH</Select.Option>
                    <Select.Option value="delete">DELETE</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={t('Body')}>
                  <Input.TextArea
                    placeholder={t('Body')}
                    value={value.body}
                    onChange={(e) =>
                      onChange({ ...value, body: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Body Mapping')}>
                  <MappingInput
                    placeholder={t('Body Mapping')}
                    value={value.bodyMapping}
                    onChange={(e) =>
                      onChange({ ...value, bodyMapping: e || undefined })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Content Type')}>
                  <Input
                    placeholder={t('Content Type')}
                    value={value.contentType}
                    onChange={(e) =>
                      onChange({ ...value, contentType: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Basic Auth Username')}>
                  <Input
                    placeholder={t('Basic Auth Username')}
                    value={value.basicAuth?.username}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        basicAuth: {
                          ...(value.basicAuth || {}),
                          username: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Basic Auth Password')}>
                  <Input.Password
                    placeholder={t('Basic Auth Password')}
                    value={value.basicAuth?.password}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        basicAuth: {
                          ...(value.basicAuth || {}),
                          password: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </Form>
            </CardWrapper>
          )}
        </Space>
      </Collapse.Panel>
    </Collapse>
  );
};

export default EventComponent;
