import { Badge, Button, Menu, Popover, Spin } from 'antd';
import { CustomsTariffNumber } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import ChatComponentImpl from '../../Pages/Chat/ChatComponentImpl';
import { BTISuggestionsList } from './BTISuggestionsList';
import { TariffClassificationInput } from './TariffClassificationInput';

export function TariffClassificationPopup({
  customTariffNumber,
  description,
  onChange = () => null,
  lineItemId,
  caseId,
  barCode,
}: {
  customTariffNumber?: CustomsTariffNumber;
  description?: string;
  onChange: (value: CustomsTariffNumber | null) => void;
  lineItemId: string;
  caseId: string;
  barCode?: string;
}) {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState('1');

  return (
    <Popover
      style={{ userSelect: 'all' }}
      content={
        <div
          style={{
            width: '400px',
            minHeight: '200px',
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          {selectedMenu === '1' && (
            <TariffClassificationInput
              _suggestions={customTariffNumber?.suggestions}
              description={description}
              taricDetails={customTariffNumber?.taric as any}
              lineItemId={lineItemId}
              caseId={caseId}
              barCode={barCode}
              onChange={({ tariffNumber, suggestions, toBeProcessed }) => {
                onChange({
                  ...(tariffNumber
                    ? {
                        value: tariffNumber,
                        input: tariffNumber,
                        suggestions,
                        hsCode: { value: tariffNumber, input: tariffNumber },
                        taric: {
                          processedByTaric: true,
                        },
                      }
                    : {}),
                  ...(toBeProcessed
                    ? {
                        taric: {
                          ...(customTariffNumber?.taric || {}),
                          toBeProcessed,
                        },
                      }
                    : {}),
                  suggestions,
                });

                // TODO reload data
              }}
            />
          )}
          {selectedMenu === '2' && (
            <BTISuggestionsList
              description={description}
              onSelect={(tariffNumber) => {
                onChange({
                  input: tariffNumber,
                  value: tariffNumber,
                  hsCode: {
                    value: tariffNumber,
                    input: tariffNumber,
                  },
                });
              }}
            />
          )}
          {selectedMenu === '3' && (
            <ChatComponentImpl
              apply
              onChange={(value) => {
                onChange({
                  value,
                  hsCode: { value },
                  taric: { processedByTaric: true },
                });
              }}
            />
          )}
        </div>
      }
      title={
        <Menu
          style={{ padding: 0 }}
          selectedKeys={[selectedMenu]}
          onSelect={(e) => {
            setSelectedMenu(e.key);
          }}
          mode="horizontal"
          items={[
            { key: '1', label: t('Classify') },
            { key: '2', label: `BTI` },
            { key: '3', label: `Chat` },
          ]}
        />
      }
      placement={'right'}
      trigger="click"
    >
      <Badge
        offset={[-2, 2]}
        dot={customTariffNumber?.suggestions?.some((c) => !c.rejected)}
      >
        <Button
          type="text"
          title={t('Let AI classify')}
          tabIndex={-1}
          icon={
            <span style={{ color: '#1677ff' }}>
              {customTariffNumber?.taric?.toBeProcessed ? (
                <Spin size="small" />
              ) : (
                <WiStars size={26} />
              )}
            </span>
          }
        />
      </Badge>
    </Popover>
  );
}
