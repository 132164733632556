import axios from 'axios';
import { LineItemModel } from 'digicust_types';
import { GENERIC_URL } from './constants';

export class LineItemRepository {
  public static get = async (
    customerId: string,
    projectId: string,
    caseId: string,
    lineItemId: string,
  ): Promise<LineItemModel | null> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseId}/line-item/${lineItemId}`,
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  public static getBatch = async (
    customerId: string,
    projectId: string,
    caseId: string,
    lineItemIds: string[],
  ): Promise<LineItemModel[]> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseId}/line-item/batch`,
        { lineItemIds, caseId },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
}
