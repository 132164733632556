import { Select, Typography } from 'antd';
import { TypeOfBusiness, TypeOfBusinessType } from 'digicust_types';
import { useTranslation } from 'react-i18next';

const TypeOfBusinessSelect = ({
  value,
  onChange = () => null,
}: {
  value?: TypeOfBusiness;
  onChange?: (value?: TypeOfBusiness) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t(
        'Final Purchase/Sale, excluding direct trade with/by private consumer(s)',
      ),
      value: TypeOfBusinessType.Purchase,
      NC_25000: '11',
      DE_A1150: '11',
    },
    {
      label: t(
        'Direct trade with/by private consumer(s), including distance selling',
      ),
      value: TypeOfBusinessType.Sample,
      NC_25000: '12',
      DE_A1150: '12',
    },
    {
      label: t('Return of goods'),
      value: TypeOfBusinessType.Return,
      NC_25000: '21',
      DE_A1150: '21',
    },
    {
      label: t('Replacement for returned goods'),
      value: TypeOfBusinessType.Replacement,
      NC_25000: '22',
      DE_A1150: '22',
    },
    {
      label: t('Replacement (e.g., due to warranty) for non-returned goods'),
      value: TypeOfBusinessType.Compensation,
      NC_25000: '23',
      DE_A1150: '23',
    },
    {
      label: t(
        'Transfers in/out of a warehouse (excluding delivery and consignment warehouses, and commission transactions)',
      ),
      value:
        TypeOfBusinessType.OtherBusinessWithTransferOfOwnershipOrCompensation,
      NC_25000: '31',
      DE_A1150: '31',
    },
    {
      label: t(
        'Sample or trial shipments (including delivery and consignment warehouses, and commission transactions)',
      ),
      value:
        TypeOfBusinessType.OtherBusinessWithTranferOwnershipWithoutCompensation,
      NC_25000: '32',
      DE_A1150: '32',
    },
    {
      label: t('Finance leasing (hire purchase)'),
      value: TypeOfBusinessType.Leasing,
      NC_25000: '33',
      DE_A1150: '33',
    },
    {
      label: t(
        'Transactions with transfer of ownership without financial consideration, including barter',
      ),
      value: TypeOfBusinessType.Barter,
      NC_25000: '34',
      DE_A1150: '34',
    },
    {
      label: t(
        'Goods sent for contract processing (no change of ownership to the processor), expected to return to the original country of export',
      ),
      value: TypeOfBusinessType.ContractProcessingComingBackToOrigin,
      NC_25000: '41',
      DE_A1150: '41',
    },
    {
      label: t(
        'Goods sent for contract processing (no change of ownership to the processor), not expected to return to the original country of export',
      ),
      value: TypeOfBusinessType.ContractProcessingNotComingBackToOrigin,
      NC_25000: '42',
      DE_A1150: '42',
    },
    {
      label: t(
        'Goods sent after contract processing (no change of ownership to the processor), returning to the original country of export',
      ),
      value: TypeOfBusinessType.AfterContractProcessingComingBackToOrigin,
      NC_25000: '51',
      DE_A1150: '51',
    },
    {
      label: t(
        'Goods sent after contract processing (no change of ownership to the processor), not returning to the original country of export',
      ),
      value: TypeOfBusinessType.AfterContractProcessingNotComingBackToOrigin,
      NC_25000: '52',
      DE_A1150: '52',
    },
    {
      label: t('Goods shipments for and after repair'),
      value: TypeOfBusinessType.Repair,
      NC_25000: '67',
      DE_A1150: '67',
    },
    {
      label: t('Customs warehousing for foreign account'),
      value: TypeOfBusinessType.SpecialNationalInterests,
      NC_25000: '68',
      DE_A1150: '68',
    },
    {
      label: t(
        'Other temporary goods traffic (for national purposes, excluding shipments under key number 41, 42, 51, 52, and 91) up to and including 24 months and other goods exempt from statistical declaration',
      ),
      value: TypeOfBusinessType.InternationalPrograms,
      NC_25000: '69',
      DE_A1150: '69',
    },
    {
      label: t(
        'Transfer of goods into free circulation in a Member State followed by export to another Member State',
      ),
      value: TypeOfBusinessType.GeneralAgreement,
      NC_25000: '71',
      DE_A1150: '71',
    },
    {
      label: t(
        'Transfer of goods from one Member State to another Member State for placing the goods under the export procedure.',
      ),
      value: TypeOfBusinessType.OtherRentalBusiness,
      NC_25000: '72',
      DE_A1150: '72',
    },
    {
      label: t(
        'Transactions involving the delivery of building materials and technical equipment as part of construction works under a general contract, where no individual goods are invoiced, but a single invoice captures the total value of the goods',
      ),
      value: TypeOfBusinessType.OtherBusiness,
      NC_25000: '81',
      DE_A1150: '81',
    },
    {
      label: t(
        'Temporary goods traffic over 24 months (e.g., rental, lending, and operate leasing)',
      ),
      value: TypeOfBusinessType.OtherBusiness,
      NC_25000: '91',
      DE_A1150: '91',
    },
    {
      label: t('Other transactions not otherwise specified'),
      value: TypeOfBusinessType.OtherBusiness,
      NC_25000: '99',
      DE_A1150: '99',
    },
  ];

  return (
    <Select
      variant="filled"
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Type of Business')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.DE_A1150}</b> {item?.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default TypeOfBusinessSelect;
