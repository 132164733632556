import { Select, Space, Typography } from 'antd';
import { UnitOfMeasurement, UOM } from 'digicust_types';
import { useUnitsOfMeasurement } from '../../utils/useCodes';

export const UnitOfMesurementSelect = ({
  value,
  onChange = () => null,
}: {
  value?: UnitOfMeasurement | null;
  onChange?: (value: UnitOfMeasurement) => void;
}) => {
  const unitsOfMeasurement = useUnitsOfMeasurement();

  return (
    <Select
      variant="filled"
      style={{ width: '100px' }}
      showSearch
      tagRender={({ label }) => <Typography.Text>{label}</Typography.Text>}
      placeholder="Unit"
      value={value?.value ? `${value?.value}+${value?.description}` : null}
      popupMatchSelectWidth={250}
      onChange={(val) => {
        const [code, description] = val.split('+');
        onChange({
          ...(value || {}),
          value: code as UOM,
          description,
        });
      }}
    >
      {unitsOfMeasurement?.map((item) => {
        return (
          <Select.Option
            title={item?.name}
            key={`${item?.code}+${item.name}`}
            value={`${item?.code}+${item.name}`}
            label={item.code}
          >
            <Space>
              <Typography.Text>{item?.code}</Typography.Text>
              <Typography.Text type="secondary">{item?.name}</Typography.Text>
            </Space>
          </Select.Option>
        );
      })}
    </Select>
  );
};
