/**
 * Provides a formatted tariff number from 12345678901 -> 12 34 56 78 90 1
 * @param tariffNumber
 * @returns
 */
export function formatTariffNumber(tariffNumber?: string): string {
  return (
    tariffNumber
      ?.replace(/\s+/g, '') // removes all spaces
      .match(/.{1,2}/g) // creates a breakpoint after every two letters
      ?.join(' ') || '' // adds space after every two letters
  );
}
