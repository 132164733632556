import { Select, Typography } from 'antd';
import { Incoterm, IncotermModel } from 'digicust_types';
import { useTranslation } from 'react-i18next';

const IncotermSelect = ({
  value,
  onChange = () => null,
}: {
  value?: IncotermModel;
  onChange?: (value?: IncotermModel) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      incoterm: Incoterm.EXW,
      text: t('Ex Works'),
    },
    {
      incoterm: Incoterm.CIP,
      text: t('Carriage Insurance Paid'),
    },
    {
      incoterm: Incoterm.CPT,
      text: t('Carriage Paid To'),
    },
    {
      incoterm: Incoterm.FCA,
      text: t('Free Carrier'),
    },
    {
      incoterm: Incoterm.DDP,
      text: t('Delivered Duty Paid'),
    },
    {
      incoterm: Incoterm.DPU,
      text: t('Delivered at Place Unloaded'),
    },
    {
      incoterm: Incoterm.DAP,
      text: t('Delivered At Place'),
    },
    {
      incoterm: Incoterm.FAS,
      text: t('Free Alongside Ship'),
    },
    {
      incoterm: Incoterm.FOB,
      text: t('Free On Board'),
    },
    {
      incoterm: Incoterm.CFR,
      text: t('Cost And Freight'),
    },
    {
      incoterm: Incoterm.CIF,
      text: t('Cost Insurance Freight'),
    },
  ];

  return (
    <Select
      variant="filled"
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Incoterm')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.incoterm === e);
        onChange({
          ...option,
          input: option?.incoterm,
          value: option?.incoterm,
        });
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.text} key={key} value={item?.incoterm}>
          <Typography.Text ellipsis>
            <b>{item.incoterm}</b> {item.text}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default IncotermSelect;
