import { Form, Input } from 'antd';
import { BEOConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function BEOSystemForm({
  config,
  changeData,
}: {
  config: BEOConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('Client code')}>
        <Input
          variant="filled"
          placeholder={t('Client code')}
          value={config?.['client-code']}
          onChange={(e) => changeData('client-code', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Customer code')}>
        <Input
          variant="filled"
          placeholder={t('Customer code')}
          value={config?.['customer-code']}
          onChange={(e) => changeData('customer-code', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Token')}>
        <Input.Password
          variant="filled"
          placeholder={t('Token')}
          value={config?.['token']}
          onChange={(e) => changeData('token', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Username')}>
        <Input
          variant="filled"
          placeholder={t('Username')}
          value={config?.['username']}
          onChange={(e) => changeData('username', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Password')}>
        <Input.Password
          variant="filled"
          placeholder={t('Password')}
          value={config?.['password']}
          onChange={(e) => changeData('password', e.target.value)}
        />
      </Form.Item>
    </Form>
  );
}
