import { Button, Input, Space, Tag } from 'antd';
import { LineItemModel } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ItemsNumber = ({
  lineItem,
  onChange,
}: {
  lineItem: LineItemModel;
  onChange: (lineItem: LineItemModel) => void;
}) => {
  const { t } = useTranslation();

  const [code, setCode] = useState<string>();
  const [materialNumber, setMaterialNumber] = useState<string>();
  const [buyerArticleNumber, setBuyerArticleNumber] = useState<string>();
  const [reference, setReference] = useState<string>();
  const [buyerOrderNumber, setBuyerOrderNumber] = useState<string>();
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    setCode(lineItem?.code?.value);
    setMaterialNumber(lineItem?.materialNumber?.value);
    setBuyerArticleNumber(lineItem?.buyerArticleNumber?.value);
    setReference(lineItem?.reference?.value);
    setBuyerOrderNumber(lineItem.buyerOrderNumber?.value);
  }, [
    lineItem?.code?.value,
    lineItem?.materialNumber?.value,
    lineItem?.buyerArticleNumber?.value,
    lineItem?.reference?.value,
    lineItem.buyerOrderNumber?.value,
  ]);

  const handleChange = (
    key:
      | 'code'
      | 'materialNumber'
      | 'buyerArticleNumber'
      | 'reference'
      | 'buyerOrderNumber',
    value?: string,
  ) => {
    onChange({
      [key]: {
        ...(lineItem[key] || {}),
        input: value,
        value: value,
        userEdited: true,
      },
    });
  };

  return edit ? (
    <Space direction="vertical">
      <Space>
        <Input
          variant="filled"
          id="Item-description"
          name="Item-description"
          title={code}
          value={code}
          placeholder={t('Article Number')}
          onChange={(e) => setCode(e.target.value)}
          onBlur={() => handleChange('code', code)}
        />
        <Input
          variant="filled"
          id="Item-MaterialNumber"
          name="Item-MaterialNumber"
          placeholder={t('Material Number')}
          title={materialNumber}
          value={materialNumber}
          onChange={(e) => setMaterialNumber(e.target.value)}
          onBlur={() => handleChange('materialNumber', materialNumber)}
        />
      </Space>
      <Space>
        <Input
          variant="filled"
          id="Item-BuyerArticleNumber"
          name="Item-BuyerArticleNumber"
          placeholder={t('Buyer Article Number')}
          title={buyerArticleNumber}
          value={buyerArticleNumber}
          onChange={(e) => setBuyerArticleNumber(e.target.value)}
          onBlur={() => handleChange('buyerArticleNumber', buyerArticleNumber)}
        />
        <Input
          variant="filled"
          id="Item-ReferenceNumber"
          name="Item-ReferenceNumber"
          placeholder={t('Reference Number')}
          title={reference}
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          onBlur={() => handleChange('reference', reference)}
        />
        <Input
          variant="filled"
          id="Item-BuyerOrderNumber"
          name="Item-BuyerOrderNumber"
          placeholder={t('Buyer Order Number')}
          title={buyerOrderNumber}
          value={buyerOrderNumber}
          onChange={(e) => setBuyerOrderNumber(e.target.value)}
          onBlur={() => handleChange('buyerOrderNumber', buyerOrderNumber)}
        />
      </Space>
    </Space>
  ) : (
    <div>
      {[
        lineItem?.code?.value,
        lineItem?.materialNumber?.value,
        lineItem?.buyerArticleNumber?.value,
        lineItem?.reference?.value,
        lineItem?.buyerOrderNumber?.value,
      ]
        .filter((i) => !!i)
        ?.map((code) => <Tag>{code}</Tag>)}
      <Button type="link" size="small" onClick={() => setEdit(true)}>
        {t('Edit')}
      </Button>
    </div>
  );
};

export default ItemsNumber;
