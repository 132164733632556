import { createSlice } from '@reduxjs/toolkit';

interface Bbox {
  header_items_boundingBox: [];
  line_items_bbox: [];
  pages_height: number;
  pages_width: number;
}

interface LineItemCell {
  row: number;
  schema_name: string;
  value: string;
  page_number: number;
  bounding_box: number[];
  confidence: number;
}

interface CheckTotalValue {
  TotalNetWeight: string;
  TotalGrossWeight: string;
}

export interface AnnotationReduxState {
  bbox: Bbox;
  existingRectangles: number[];
  lineItemsOpen: any;
  discardActiveObject: boolean;
  closePopup: boolean;
  nextbbox: string;
  pressTabKey: string;
  checkTotalValue: CheckTotalValue;
  stopFocuse: boolean;
}

export const initialAnnotationState: AnnotationReduxState = {
  bbox: {
    header_items_boundingBox: [],
    line_items_bbox: [],
    pages_height: 0,
    pages_width: 0,
  },
  checkTotalValue: {
    TotalNetWeight: '',
    TotalGrossWeight: '',
  },
  stopFocuse: true,
  discardActiveObject: true,
  existingRectangles: [],
  closePopup: false,
  pressTabKey: '',
  nextbbox: '',
  lineItemsOpen: {
    key: '',
    open: false,
    itemkey: '',
    value: '',
    rowIndex: -1,
  },
};

const annotationSlice = createSlice({
  name: 'case',
  initialState: initialAnnotationState,
  reducers: {
    setBboxs: (state, action) => {
      const { bbox, type } = action.payload;
      if (type === 'all') {
        const lineItemsBbox = bbox?.line_items_bbox?.flatMap(
          (item: any, index: number) =>
            Object.entries(item)?.map(([key, value]) => ({
              ...((value as LineItemCell) || {}),
              schema_name: key,
              row: index,
            })),
        );
        const data = {
          ...bbox,
          line_items_bbox: lineItemsBbox,
        };
        state.bbox = data;
      } else {
        state.bbox = bbox;
      }
    },
    setExistingRectangles: (state, action) => {
      state.existingRectangles = action.payload;
    },
    setLineItemsOpen: (state, action) => {
      state.lineItemsOpen = action.payload;
    },
    setDiscardActiveObject: (state, action) => {
      state.discardActiveObject = action.payload;
    },
    setClosePopup: (state, action) => {
      state.closePopup = action.payload;
    },
    setNextBbox: (state, action) => {
      state.nextbbox = action.payload;
    },
    setPressTabKey: (state, action) => {
      state.pressTabKey = action.payload;
    },
    setCheckTotalValue: (state, action) => {
      state.checkTotalValue = action.payload;
    },
    setStopFocus: (state, action) => {
      state.stopFocuse = action.payload;
    },
  },
});

export const {
  setBboxs,
  setExistingRectangles,
  setLineItemsOpen,
  setDiscardActiveObject,
  setClosePopup,
  setNextBbox,
  setPressTabKey,
  setCheckTotalValue,
  setStopFocus,
} = annotationSlice.actions;
export default annotationSlice.reducer;
