import { Button, Card, Flex, Typography } from 'antd';
import React, { useState } from 'react';
import TariffClassifierCard from './TariffClassifierCard';
import TariffTree from './TariffTree';
import { useAppDataContext } from '../AppDataProvider';
import ChatComponentImpl from '../Chat/ChatComponentImpl';
import { ExportOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const TariffClassification: React.FC = () => {
  const { projectDetails } = useAppDataContext();
  const [activeTab, setActiveTab] = useState<'chat' | 'tariff'>('chat');
  const navigate = useNavigate();
  const pathSegments = location.pathname.split('/');
  const customerId = pathSegments[1];
  const projectId = pathSegments[2];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
      }}
    >
      {/* Tab selectors */}
      <Flex
        justify="space-between"
        gap={20}
        style={{ padding: '20px 30px', borderBottom: '1px solid #e8e8e8' }}
      >
        <Flex justify="start" gap={20}>
          <div
            onClick={() => setActiveTab('chat')}
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              borderBottom: activeTab === 'chat' ? '2px solid #1890ff' : 'none',
              fontWeight: activeTab === 'chat' ? 'bold' : 'normal',
            }}
          >
            Chat Taric
          </div>
          <div
            onClick={() => setActiveTab('tariff')}
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              borderBottom:
                activeTab === 'tariff' ? '2px solid #1890ff' : 'none',
              fontWeight: activeTab === 'tariff' ? 'bold' : 'normal',
            }}
          >
            Tariff Information
          </div>
        </Flex>

        {!pathSegments.includes('tariff-classification') && (
          <Button
            type="text"
            target="_blank"
            href={`/${customerId}/${projectId}/tariff-classification`}
            icon={<ExportOutlined />}
          ></Button>
        )}
      </Flex>

      {/* Content area */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          padding: '30px',
          overflow: 'hidden',
        }}
      >
        {activeTab === 'chat' ? (
          <div style={{ flex: 1, height: '100%', overflow: 'auto' }}>
            <ChatComponentImpl />
          </div>
        ) : (
          <Flex
            vertical
            gap={30}
            style={{ width: '100%', height: '100%', overflow: 'auto' }}
          >
            <TariffClassifierCard />

            <span style={{ flex: 1, width: '100%' }}>
              <Typography.Title level={5}>
                Tariff number tree ({projectDetails?.tariffNumberTreeSystem})
              </Typography.Title>
              <Card
                style={{
                  maxHeight: '100%',
                  overflow: 'auto',
                  marginTop: '10px',
                }}
              >
                <TariffTree />
              </Card>
            </span>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default TariffClassification;
