import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { config } from '../config';

interface RequestInterceptorProps {
  children: JSX.Element;
}

// for flag purpose not to direct
let addRedirectTimeout: any;

// Msal Token interceptor
const RequestInterceptor = ({ children }: RequestInterceptorProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const loginRedirect = async () => {
    await instance.loginRedirect(config.msalRequest);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      // if user is not Authenyicated redirecting to back
      addRedirectTimeout = setTimeout(() => {
        loginRedirect();
      }, 1000);
    } else {
      if (addRedirectTimeout) {
        clearTimeout(addRedirectTimeout);
        addRedirectTimeout = undefined;
      }
    }
  }, [isAuthenticated]);

  // if no account is activated
  if (!account) {
    return null;
  }

  const getToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...config.msalRequest,
        account: accounts[0],
      });
      localStorage.setItem('tokon', response.accessToken);
      return response.accessToken;
    } catch (error) {
      instance.loginRedirect(config.msalRequest);
      return '';
    }
  };

  axios.interceptors.request.use(
    async (reqConfig) => {
      const token: string | null = localStorage.getItem('tokon');

      let bearer = '';
      if (!token) {
        const newToken = await getToken();
        bearer = `Bearer ${newToken}`;
      } else {
        const tokenDecoded: string | any = jwt_decode(token);
        const CurrentTime = Math.floor(Date.now() / 1000);
        if (tokenDecoded?.exp > CurrentTime) {
          bearer = `Bearer ${token}`;
        } else {
          const newToken = await getToken();
          bearer = `Bearer ${newToken}`;
        }
      }

      if (reqConfig?.headers) {
        reqConfig.headers.Authorization = bearer;

        // this is important for all the calls from frontend to generic, upload service for authentication.
        // Without this the auth will fail
        reqConfig.headers['x-auth-type'] = 'b2c';
      }

      return reqConfig;
    },
    (error) => {
      if (error && error.status === 401) {
        notification.error({
          message: 'Unauthorized',
          description:
            'You are not authorized to perform this action. Please log out and log back in.',
        });
        // Optionally, you can also clear the token or initiate a logout
        // localStorage.removeItem('tokon');
        // instance?.logout();
      }
      return Promise.reject(error);
    },
  );

  return <React.Fragment>{children}</React.Fragment>;
};

export default RequestInterceptor;
