import { message, Spin } from 'antd';
import { ProjectModel, UserModel } from 'digicust_types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProjectsRepository } from '../utils/repositories/projects.repository';
import { useUser } from '../utils/useUser';
import { LoadingOutlined } from '@ant-design/icons';

type AppData = {
  customerId: string;
  projectId: string;
  isAdmin: boolean;
  user: UserModel;
  projectDetails?: ProjectModel;
  loadProject: () => Promise<void>;
};

const AppDataContext = React.createContext<AppData>({} as AppData);

export function useAppDataContext() {
  return useContext(AppDataContext);
}

export default function AppDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isAdmin, user } = useUser();
  const { customerId, projectId } = useParams();

  const { t } = useTranslation();

  const [projectDetails, setProjectDetails] = useState<ProjectModel>();

  const loadProject = async (showWarning?: boolean) => {
    const project = await ProjectsRepository.get(customerId, projectId);

    if (showWarning && project?.taricClassifications) {
      const percent =
        (project?.taricClassifications?.total /
          project?.taricClassifications?.limit) *
        100;

      if (percent > 90)
        message.warning(`${percent}% ${t('classification limit utilized.')}`);
    }
    setProjectDetails(project);
  };

  useEffect(() => {
    loadProject(true);
  }, [customerId, projectId]);

  const context: AppData = useMemo(() => {
    const data: AppData = {
      customerId: customerId!,
      projectId: projectId!,
      isAdmin,
      user,
      projectDetails,
      loadProject,
    };
    return data;
  }, [customerId, projectId, isAdmin, user, projectDetails]);

  if (!projectDetails) {
    return (
      <Spin
        indicator={<LoadingOutlined spin />}
        style={{ marginTop: 100 }}
        size="large"
      />
    );
  }

  return (
    <AppDataContext.Provider value={context}>
      {children}
    </AppDataContext.Provider>
  );
}
