import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Flex,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  InfoOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import styles from './CaseList.module.css';
import { t } from 'i18next';
import { Content, Header } from 'antd/es/layout/layout';
import { FieldRangePicker, PageHeader } from '@ant-design/pro-components';
import { CaseModel, FilterCondition, Meta } from 'digicust_types';

import { MenuTitleInfo } from 'rc-menu/lib/interface';
import { useAppDataContext } from '../../AppDataProvider';
import { CaseRepository } from '../../../utils/repositories/case.repository';
import { asyncForEach } from '../../../utils/helpers/asyncForEach';
import { LineItemRepository } from '../../../utils/repositories/lineItem.repository';

type CustomsCase = {
  id: string;
  reference: string;
  summary: string;
  status: string;
  toCountry: string;
  fromCountry: string;
  shipperName: string;
  consigneeName: string;
};

export const LeanCustomsCase: React.FC = () => {
  const { customerId, projectId, isAdmin } = useAppDataContext();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<CaseModel | undefined>(undefined);

  const loadData = async () => {
    setLoading(true);
    if (id !== 'new') {
      const response = await CaseRepository.get(customerId, projectId, id, {
        skipReadLocks: true,
        condensed: true,
        // limit: itemLimit,
      });
      setData(response as CaseModel);
      const lineItems = response?.aggregated?.items || [];
      await asyncForEach(lineItems, async (item, index) => {
        const lineItemResponse = await LineItemRepository.get(
          customerId!,
          projectId!,
          id!,
          item.id!,
        );
        lineItems[index] = lineItemResponse || lineItems[index];
        setData({
          ...response,
          aggregated: { ...(response?.aggregated || {}), items: lineItems },
        });
      });
      setLoading(false);
    } else {
      setData({});
    }
  };

  useEffect(() => {
    loadData();
  }, [customerId, projectId, id]);

  const handleNavigation = (
    url: string,
    event: MenuTitleInfo | React.MouseEvent,
  ) => {
    if (
      (event as MenuTitleInfo)?.domEvent?.ctrlKey
        ? (event as MenuTitleInfo).domEvent.ctrlKey ||
          (event as MenuTitleInfo)?.domEvent?.metaKey
        : (event as React.MouseEvent).ctrlKey ||
          (event as React.MouseEvent).metaKey
    ) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  return (
    <Layout>
      <PageHeader
        title={
          data?.reference ||
          data?.id ||
          (loading ? <Skeleton.Input active /> : t('New Case'))
        }
        extra={[
          <Button
            type="text"
            loading={loading}
            icon={<ReloadOutlined />}
            // onClick={async () => await fetchCases()}
          ></Button>,
        ]}
      />
      <Content>
        <Row>
          <Col
            flex="250px"
            style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 60px)' }}
          >
            <Tree
              showLine
              titleRender={(node) => (
                <Space direction="horizontal" style={{ height: 18.5 }}>
                  <Typography.Text
                    title={node.title}
                    style={{ maxWidth: 150 }}
                    ellipsis
                  >
                    {node.title}
                  </Typography.Text>
                  {/* <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                  ></Button> */}
                </Space>
              )}
              defaultExpandAll
              //   multiple
              treeData={[
                {
                  title: t('General'),
                  key: 'general',

                  children: [],
                },
                {
                  title: t('Stakeholders'),
                  key: 'stakeholders',

                  children: [
                    {
                      title: t('Shipper'),
                      key: 'shipper',

                      children: [],
                    },
                  ],
                },
                {
                  title: t('Customs Offices'),
                  key: 'customsOffices',

                  children: [],
                },
                {
                  title: t('Transportation'),
                  key: 'transportation',

                  children: [],
                },
                {
                  title: t('Items'),
                  key: 'items',
                  children: data?.aggregated?.items?.map((item) => ({
                    title: item.description?.value || item.id,
                    key: item.id || '',
                    children: [],
                  })),
                },
                {
                  title: t('Documents'),
                  key: 'documents',

                  children: [],
                },
              ]}
            />
          </Col>
          <Col flex="auto">
            <Space direction="vertical" style={{ width: '100%' }}>
              {[
                { title: t('Procedure'), suggestions: ['4000'] },
                {
                  title: t('Representation'),
                  suggestions: ['None', 'Direct', 'Indirect'],
                },
                {
                  title: t('Incoterm'),
                  getValue: () => data?.aggregated?.incoterm?.value,
                  getMeta: () => data?.aggregated?.incoterm as Meta<any>,
                  setValue: (value: string) => {
                    setData({
                      ...(data || {}),
                      aggregated: {
                        ...(data?.aggregated || {}),
                        incoterm: {
                          source: 'userAnnotation',
                          input: value,
                          value: value as any,
                        },
                      },
                    });
                  },
                  suggestions: ['EXW', 'FCA', 'DDP'],
                },
                {
                  title: t('Incoterm place'),
                  suggestions: [],
                },
              ].map((field) => (
                <Row>
                  <Col
                    flex="150px"
                    style={{
                      textAlign: 'right',
                      paddingTop: '3px',
                      paddingRight: '10px',
                    }}
                  >
                    {field.title}
                    <Button
                      tabIndex={-1}
                      size="small"
                      type="text"
                      icon={<QuestionCircleOutlined />}
                    />
                  </Col>
                  <Col flex="auto">
                    <Space
                      size={0}
                      style={{ width: '100%' }}
                      direction="vertical"
                    >
                      <Input
                        value={field.getValue?.() || field.getMeta?.()?.value}
                        onChange={(e) => field.setValue?.(e.target.value)}
                        style={{ width: '100%' }}
                        suffix={
                          <Space direction="horizontal">
                            {field.getMeta?.()?.source && (
                              <Tooltip title={field.getMeta?.()?.source}>
                                <Badge status="success" />
                              </Tooltip>
                            )}
                          </Space>
                        }
                        variant="filled"
                      />
                      {!(field.getValue?.() || field.getMeta?.()?.value) &&
                        field.suggestions && (
                          <Space direction="horizontal">
                            {field.suggestions?.map((suggestion) => (
                              <Button
                                tabIndex={-1}
                                size="small"
                                type="link"
                                onClick={() => field?.setValue?.(suggestion)}
                              >
                                {suggestion}
                              </Button>
                            ))}
                          </Space>
                        )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </Space>
          </Col>
          <Col flex="40%" style={{ textAlign: 'right', paddingRight: '10px' }}>
            {/* {JSON.stringify(data)} */}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
