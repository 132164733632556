import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const MappingInput = ({
  value,
  onChange,
  placeholder,
}: {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Input
      variant="filled"
      placeholder={placeholder || t('JSONata Mapping')}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      suffix={
        <a target="_blank" rel="noreferrer" href="https://jsonata.org/">
          <Button
            type="text"
            icon={
              <Typography.Text type="secondary">
                <QuestionCircleFilled />
              </Typography.Text>
            }
            size={'small'}
          />
        </a>
      }
    />
  );
};

export default MappingInput;
